import { ReactNode } from "react";
import { InteractiveContainer, Tooltip } from "@jugl-web/ui-components";
import { cx } from "@jugl-web/utils";

export const SidebarLinkButton = ({
  text,
  icon,
  isActive,
  tooltip,
  isSidebarExpanded,
  className,
  onClick,
}: {
  text: ReactNode;
  icon: ReactNode;
  isActive?: boolean;
  tooltip?: ReactNode;
  isSidebarExpanded?: boolean;
  className?: string;
  onClick?: () => void;
}) => (
  <Tooltip
    isDisabled={isSidebarExpanded || !tooltip}
    placement="right"
    renderTrigger={({ props, ref }) => (
      <InteractiveContainer
        className={cx(
          "font-secondary text-primary-50 mx-2.5 flex h-[41px] items-center gap-2 rounded-lg p-2.5 text-sm hover:bg-[#2F7DDA]",
          {
            "bg-primary-800 font-semibold": isActive,
            "w-[60px] flex-col justify-center overflow-hidden text-center":
              !isSidebarExpanded,
            "w-[190px]": isSidebarExpanded,
          },
          className
        )}
        {...props}
        onClick={onClick}
        ref={ref}
      >
        {icon}
        {isSidebarExpanded && text}
      </InteractiveContainer>
    )}
  >
    {tooltip}
  </Tooltip>
);
