import React from "react";

export interface BoardAvatarProps {
  name: string;
  color: string;
}

export const BoardAvatar: React.FC<BoardAvatarProps> = ({ name, color }) => (
  <div
    style={{
      backgroundColor: color,
    }}
    className="font-secondary flex h-4 w-4 select-none items-center justify-center rounded-[4px] text-[10px] font-semibold text-white"
  >
    {name.charAt(0)}
  </div>
);
