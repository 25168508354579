import {
  TaskChecklist,
  getDueDateBasedOnDays,
  templateChecklistItemAdapters,
} from "@jugl-web/domain-resources/tasks";
import { DescriptionBox } from "@jugl-web/domain-resources/tasks/components/DescriptionBox";
import { TaskPropertiesPanel } from "@jugl-web/domain-resources/tasks/components/TaskPropertiesPanel";
import { TitleBox } from "@jugl-web/domain-resources/tasks/components/TitleBox";
import { DetailedTaskTemplate } from "@jugl-web/rest-api/tasks-templates";
import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import { useTranslations } from "@jugl-web/utils";
import { useScreenTransitionManager } from "@jugl-web/utils/utils/ScreenTransitionManager";
import { TaskFormDialog } from "@web-src/features/tasks/TaskFormDialog";
import { FC, useMemo } from "react";
import { TemplatePreviewDialogScreenToParametersMap } from "../../types";

interface TemplatePreviewScreenProps {
  template: DetailedTaskTemplate;
  entityId: string;
  meId: string;
}

export const TemplatePreviewScreen: FC<TemplatePreviewScreenProps> = ({
  template,
  entityId,
  meId,
}) => {
  const { transitionTo } =
    useScreenTransitionManager<TemplatePreviewDialogScreenToParametersMap>();

  const { t } = useTranslations();

  const adaptedChecklistItems = useMemo(
    () => template.checklist.map(templateChecklistItemAdapters.toInternalModel),
    [template.checklist]
  );

  return (
    <>
      <TaskFormDialog.Content className="animate-fade-in">
        <TaskFormDialog.TitleSection>
          <TitleBox
            title={template.name || ""}
            isEditable={false}
            classes={{ titleText: "py-3" }}
          />
          {template.desc && (
            <DescriptionBox
              description={template.desc}
              isEditable={false}
              classes={{ descriptionText: "mt-2 py-2" }}
            />
          )}
          <TaskPropertiesPanel
            entityId={entityId}
            config={{
              dueDate: {
                state: template.due_in
                  ? { date: getDueDateBasedOnDays(template.due_in) }
                  : null,
                displayAs: "days",
                isReadonly: true,
              },
              board: {
                boardId: template.board_id,
                isReadonly: true,
              },
              label: {
                labelId: template.label_id,
                isReadonly: true,
              },
              priority: {
                priority: template.priority,
                isReadonly: true,
              },
              customFields: {
                valuesById: template.custom_fields || {},
                isReadonly: true,
              },
            }}
            className="mt-8"
          />
        </TaskFormDialog.TitleSection>
        <TaskFormDialog.ChecklistSection>
          <TaskChecklist
            entityId={entityId}
            meId={meId}
            items={adaptedChecklistItems}
            isEditable={false}
            isCompletable={false}
            displayDueDateAs="days"
          />
        </TaskFormDialog.ChecklistSection>
        {/* To be done */}
        {/* <TaskFormDialog.AttachmentsSection>
        </TaskFormDialog.AttachmentsSection> */}
      </TaskFormDialog.Content>
      <TaskFormDialog.Actions className="animate-fade-in">
        <Button
          variant="contained"
          color="primary"
          className="h-10 w-[200px]"
          onClick={() => transitionTo({ name: "taskCreation" })}
        >
          {t({
            id: "tasks-page.use-template",
            defaultMessage: "Use template",
          })}
        </Button>
      </TaskFormDialog.Actions>
    </>
  );
};
