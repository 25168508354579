import { useCallback, useState, useSyncExternalStore } from "react";
import { getStorageItemWithFallback, saveItemToStorage } from "../storage";

const subscribeToLocalStorageEvents = (callback: () => void): (() => void) => {
  window.addEventListener("storage", callback);
  return () => {
    window.removeEventListener("storage", callback);
  };
};

const produceUseStorage =
  (storageGetter: () => Storage) =>
  <TValue>(key: string, initialValue: TValue) => {
    const storage = storageGetter();

    const [storedValue, setStoredValue] = useState(() =>
      getStorageItemWithFallback(key, initialValue, storage)
    );

    const setValue = useCallback(
      (value: TValue | ((previousValue: TValue) => TValue)) => {
        setStoredValue((previousStoredValue) => {
          const resolvedValue =
            value instanceof Function ? value(previousStoredValue) : value;

          saveItemToStorage(key, resolvedValue, storage);

          return resolvedValue;
        });
      },
      [key, storage]
    );

    return [storedValue, setValue] as const;
  };

const produceSyncUseStorage =
  (storageGetter: () => Storage) =>
  <TValue>(key: string, initialValue: TValue) => {
    const storage = storageGetter();

    const [, setStoredValue] = useState(() =>
      getStorageItemWithFallback(key, initialValue, storage)
    );
    const syncedValue = useSyncExternalStore(
      subscribeToLocalStorageEvents,
      () => getStorageItemWithFallback(key, "personal")
    ) as TValue;

    const setValue = useCallback(
      (value: TValue | ((previousValue: TValue) => TValue)) => {
        setStoredValue((previousStoredValue) => {
          const resolvedValue =
            value instanceof Function ? value(previousStoredValue) : value;

          saveItemToStorage(key, resolvedValue, storage);

          return resolvedValue;
        });
        window.dispatchEvent(new Event("storage"));
      },
      [key, storage]
    );

    return [syncedValue, setValue] as const;
  };

export const useLocalStorage = produceUseStorage(() => localStorage);
export const useSessionStorage = produceUseStorage(() => sessionStorage);
export const useSyncLocalStorage = produceSyncUseStorage(() => localStorage);
