import React, { useCallback, useMemo, useState } from "react";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import {
  ListBoxItem,
  ListBoxProps,
  TaskPropertyButton,
  TaskPropertyButtonProps,
  BoardAvatar,
} from "@jugl-web/ui-components";
import { useAppVariant, useToast, useTranslations } from "@jugl-web/utils";
import { ResourcePickerDrawer } from "@jugl-web/ui-components/mobile/ResourcePickerDrawer";
import { hexColorWithTransparency } from "@jugl-web/utils/utils/hexColorWithTransparency";
import { TaskBoardModel } from "@jugl-web/rest-api/tasks/models/TaskBoard";
import { ReactComponent as BoardIcon } from "./assets/board.svg";
import { FieldComponentProps } from "../../types";
import { useTaskBoards } from "../../../../hooks/useTaskBoards";

interface BoardFieldProps extends FieldComponentProps<"board"> {
  entityId: string;
}

export const BoardField: React.FC<BoardFieldProps> = ({
  boardId,
  entityId,
  isReadonly,
  shouldShowUpdateToast,
  onChange,
}) => {
  const { t } = useTranslations();
  const { isMobile, variant } = useAppVariant();
  const { toast } = useToast({ variant });
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { boards, isLoading, getBoardById } = useTaskBoards({
    entityId,
  });

  const selectedBoardDetails = useMemo(
    () => (boardId ? getBoardById(boardId) : null),
    [boardId, getBoardById]
  );

  const boardAsListItems = useMemo<ListBoxItem<TaskBoardModel>[]>(
    () =>
      boards?.map((board) => ({
        id: board.id,
        value: board,
      })) || [],
    [boards]
  );

  const commonButtonProps: TaskPropertyButtonProps = {
    isDisabled: isReadonly,
    startIcon: selectedBoardDetails ? (
      <BoardAvatar {...selectedBoardDetails} />
    ) : (
      <BoardIcon />
    ),
    className: "max-w-[250px]",
    style: {
      color: selectedBoardDetails?.color,
      backgroundColor: selectedBoardDetails
        ? hexColorWithTransparency(selectedBoardDetails.color, 12)
        : undefined,
    },
    children: selectedBoardDetails
      ? t(
          {
            id: "tasks-page.board-name",
            defaultMessage: "Board: {boardName}",
          },
          {
            boardName: selectedBoardDetails.name,
          }
        )
      : t({
          id: "tasks-page.select-board",
          defaultMessage: "Select Board",
        }),
  };

  const commonResourcePickerProps = {
    title: t({
      id: "tasks-page.move-task",
      defaultMessage: "Move Task",
    }),
    items: boardAsListItems,
    selectionBehavior: { mode: "single", canToggle: true },
    hasSearch: true,
    shouldFilterOnSearch: true,
    loading: isLoading ? "skeleton" : undefined,
    defaultSelectedIds: boardId ? [boardId] : undefined,
    maxVisibleItems: 6,
    itemSize: "lg",
    spaceBetweenItems: "normal",
    renderLabel: (item) => item.value.name,
    renderStartIcon: (item) => <BoardAvatar {...item.value} />,
  } satisfies ListBoxProps<TaskBoardModel> & { title: string };

  const handleUpdate = useCallback(
    (board?: TaskBoardModel) => {
      onChange?.(board?.id || null);
      if (shouldShowUpdateToast && board) {
        toast(
          <span>
            {t(
              {
                id: "tasks-page.task-moved-to-board",
                defaultMessage: "Task has been moved to {boardName}",
              },
              { boardName: <span className="font-semibold">{board.name}</span> }
            )}
          </span>
        );
      }
    },
    [shouldShowUpdateToast, onChange, t, toast]
  );

  if (isMobile) {
    return (
      <>
        <TaskPropertyButton
          onClick={() => setIsDialogOpen(true)}
          {...commonButtonProps}
          data-id="board-field"
        />
        <ResourcePickerDrawer
          isOpen={isDialogOpen}
          onSubmit={(_, [item]) => handleUpdate(item?.value)}
          onClose={() => setIsDialogOpen(false)}
          {...commonResourcePickerProps}
        />
      </>
    );
  }

  return (
    <ResourcePickerPopover
      placement="bottom-start"
      className="w-[375px]"
      shouldCloseOnAddButtonClick
      renderTrigger={({ Trigger, triggerRef }) => (
        <Trigger
          ref={triggerRef}
          as={TaskPropertyButton}
          {...commonButtonProps}
          data-id="board-field"
        />
      )}
      onSelect={({ item, isSelected, onClose }) => {
        handleUpdate(isSelected ? item.value : undefined);
        onClose();
      }}
      {...commonResourcePickerProps}
    />
  );
};
