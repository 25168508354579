import { cx } from "@jugl-web/utils";
import { ReactNode } from "react";

export const ProjectIcon = ({
  sign,
  bgColor,
  className,
}: {
  sign: ReactNode;
  bgColor: string;
  className?: string;
}) => (
  <div
    style={{
      backgroundColor: bgColor,
    }}
    className={cx(
      "font-secondary flex h-[18px] w-[18px] select-none items-center justify-center rounded-[4px] border-[1px] border-solid border-white text-[11px] font-semibold text-white transition-colors duration-300",
      className
    )}
  >
    {sign}
  </div>
);
