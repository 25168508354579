import { useRestApiProvider } from "@jugl-web/rest-api";
import { useCallback } from "react";

export const useTaskBoards = ({ entityId }: { entityId: string }) => {
  const { tasksApi } = useRestApiProvider();
  const {
    data: boards,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = tasksApi.useGetTaskBoardsQuery({
    entityId,
  });

  const getBoardById = useCallback(
    (boardId: string) => boards?.find((board) => board.id === boardId) || null,
    [boards]
  );

  return { boards, isLoading, isFetching, isError, refetch, getBoardById };
};
