import { ListBoxProps } from "@jugl-web/ui-components/cross-platform/ListBox";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { FC, ReactNode, useMemo } from "react";
import { useUserListBox } from "@jugl-web/domain-resources/users/hooks/useUserListBox";
import { HeadlessUserItem } from "@jugl-web/domain-resources/users/hooks/useHeadlessUsersList";
import { InteractiveContainer, AvatarMember } from "@jugl-web/ui-components";
import { AvatarStack } from "@jugl-web/ui-components/cross-platform/AvatarStack";
import { cx, useTranslations } from "@jugl-web/utils";
import { useMultipleUserProfiles } from "@jugl-web/domain-resources/users/hooks/useMultipleUserProfiles";
import { BoardRoles } from "@jugl-web/rest-api/tasks";

export interface UserPickerProps {
  entityId: string;
  users: { id: string; type: BoardRoles }[];
  meId: string;
  variant: "member" | "owner";
  isReadonly?: boolean;
  onChange?: (item: string[]) => void;
}
export const UserPicker: FC<UserPickerProps> = ({
  entityId,
  users,
  meId,
  variant,
  isReadonly,
  onChange,
}) => {
  const membersIds = useMemo(
    () => users.filter((user) => user.type === "member").map((user) => user.id),
    [users]
  );
  const ownersIds = useMemo(
    () => users.filter((user) => user.type === "owner").map((user) => user.id),
    [users]
  );

  const variantIds = variant === "member" ? membersIds : ownersIds;

  const { t } = useTranslations();
  const { profiles } = useMultipleUserProfiles({
    entityId,
    userIds: variantIds,
  });

  const title = useMemo<ReactNode>(() => {
    if (variant === "owner") {
      return t({
        id: "tasks-board-dialog.owners",
        defaultMessage: "Owners",
      });
    }
    return t({
      id: "tasks-board-dialog.board-members",
      defaultMessage: "Board members",
    });
  }, [variant, t]);

  const profilesAsAvatarMembers = useMemo<AvatarMember[]>(
    () =>
      profiles.map((profile) => ({
        firstName: profile.firstName || "",
        lastName: profile.lastName || "",
        imageUrl: profile.avatar || null,
      })),
    [profiles]
  );

  const { getListBoxProps, clearSearchQuery } = useUserListBox({
    entityId,
    excludeUsers: `${meId}${
      (ownersIds && ownersIds.map((id) => `,${id}`).join("")) || ""
    }`,
  });

  const commonResourcePickerProps = {
    ...getListBoxProps({
      selectionBehavior: { mode: "multiple" },
      defaultSelectedIds: variant === "member" ? membersIds : ownersIds,
    }),
    ...{
      title,
      subtitle:
        variant === "owner"
          ? t({
              id: "tasks-board-dialog.owners-picker-description",
              defaultMessage:
                "Board Owner will have full permission to manage it",
            })
          : undefined,
    },
  } satisfies ListBoxProps<HeadlessUserItem | null>;

  return (
    <ResourcePickerPopover
      placement="right-end"
      className="w-[375px]"
      renderTrigger={({ Trigger, triggerRef, isOpen: isPickerOpen }) => (
        <Trigger
          isDisabled={isReadonly}
          as={InteractiveContainer}
          ref={triggerRef}
          className="mt-10 h-[63px]"
        >
          <div className="text-dark-800 text-left text-xs">{title}</div>
          <div
            className={cx(
              "font-secondary text-dark border-dark-100 mt-2 flex w-full items-center justify-between border-0 border-b-[1px] border-solid pb-2 text-base",
              {
                "border-primary": isPickerOpen,
              }
            )}
          >
            {variant === "member" &&
              t(
                {
                  id: "tasks-board-dialog.count-members",
                  defaultMessage:
                    "{count} {count, plural, one {member} other {members}}",
                },
                { count: membersIds.length }
              )}
            {variant === "owner" &&
              t(
                {
                  id: "tasks-board-dialog.count-owners",
                  defaultMessage:
                    "{count} {count, plural, one {Owner} other {Owners}}",
                },
                { count: ownersIds.length }
              )}
            <AvatarStack
              members={profilesAsAvatarMembers}
              maxCount={5}
              size="lg"
            />
          </div>
        </Trigger>
      )}
      onSubmit={(item) => {
        onChange?.(item);
        clearSearchQuery();
      }}
      onUnmount={clearSearchQuery}
      {...commonResourcePickerProps}
    />
  );
};
