import { cx } from "@jugl-web/utils";
import { InteractiveContainer, Avatar, Popover } from "@jugl-web/ui-components";
import { CreateNewEntityButton } from "@web-src/modules/entities/components/CreateNewEntityButton";
import { EntityCard } from "@web-src/modules/entities/components/EntityCard";
import { EntityInvitationCard } from "@web-src/modules/entities/components/EntityInvitationCard";
import {
  LeaveEntityConfirmationDialogsFlow,
  LeaveEntityConfirmationDialogsFlowHandle,
} from "@web-src/modules/entities/components/LeaveEntityConfirmationDialogsFlow";
import { useRef, useMemo } from "react";
import { useEntitiesListProvider } from "@web-src/features/entities/providers/EntitiesListProvider/EntitiesListProvider";
import useEntity from "../../../../hooks/useEntity";
import bellGif from "./assets/bell.gif";
import { ReactComponent as ChevronUpIcon } from "./assets/chevron-up.svg";

export const SelectEntityPopover: React.FC = () => {
  const { entity } = useEntity();
  const {
    entitiesState: { entities },
    entityInvitesState: { entityInvites },
  } = useEntitiesListProvider();

  const hasUnreadInDifferentEntity = useMemo(
    () => entities?.some((entityItem) => entityItem.unread),
    [entities]
  );

  const leaveEntityConfirmationDialogsFlowRef =
    useRef<LeaveEntityConfirmationDialogsFlowHandle | null>(null);

  return (
    <Popover
      className="z-40"
      placement="bottom-start"
      renderTrigger={({ Trigger, triggerRef, isOpen }) => (
        <Trigger
          as={InteractiveContainer}
          ref={triggerRef}
          className={cx(
            "font-secondary hover:bg-primary-700 ml-7 flex max-w-[250px] items-center justify-center gap-3 rounded-lg px-3 py-1 text-sm font-medium text-white outline-none duration-300 ease-out",
            {
              "bg-primary-700": isOpen,
            }
          )}
        >
          <div className="relative">
            <Avatar
              size="sm"
              username={entity?.name || ""}
              imageUrl={entity?.logoImg}
            />
            {hasUnreadInDifferentEntity && (
              <span className="bg-primary absolute right-[-6px] top-[10.5px] flex h-[15px] w-[15px] items-center justify-center rounded-md">
                <img src={bellGif} alt="" className="h-[21px] w-[21px]" />
              </span>
            )}
          </div>
          <div
            className="font-secondary max-w-[300px] truncate text-sm font-medium text-white"
            title={entity?.name}
          >
            {entity?.name}
          </div>
          <ChevronUpIcon
            className={cx({
              "rotate-180": !isOpen,
            })}
          />
        </Trigger>
      )}
    >
      <div className="max-h-[551px] w-[440px] overflow-y-auto p-4">
        <CreateNewEntityButton />
        <div className="w-full py-4">
          <div className="bg-dark-100 h-[1px] w-full" />
        </div>
        <div className="flex flex-col gap-1.5">
          {entities?.map((entityItem) => (
            <EntityCard
              key={entityItem.id}
              entity={entityItem}
              onLeaveEntity={() =>
                leaveEntityConfirmationDialogsFlowRef.current?.triggerLeaveEntityFlow(
                  entityItem
                )
              }
              isSelected={entityItem.id === entity?.id}
              variant="popover"
              showToastOnSwitch
            />
          ))}
        </div>
        {entityInvites?.length ? (
          <div className="w-full py-4">
            <div className="bg-dark-100 h-[1px] w-full" />
          </div>
        ) : null}
        {entityInvites?.map((invitation) => (
          <EntityInvitationCard invitation={invitation} key={invitation.id} />
        ))}
      </div>
      <LeaveEntityConfirmationDialogsFlow
        ref={leaveEntityConfirmationDialogsFlowRef}
      />
    </Popover>
  );
};
