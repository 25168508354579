import { useCustomers } from "@jugl-web/domain-resources/customers/hooks/useCustomers";
import { TaskViewsProvider } from "@jugl-web/domain-resources/tasks";
import { TaskFilteringProvider } from "@jugl-web/domain-resources/tasks/components/TaskFilteringProvider";
import { TaskSortingProvider } from "@jugl-web/domain-resources/tasks/components/TaskSortingProvider";
import { HeaderBreadcrumbsItem } from "@jugl-web/ui-components/web/HeaderBreadcrumbs";
import { useTranslations } from "@jugl-web/utils";
import { TasksPageLayout } from "@web-src/components/TasksPageLayout";
import { TasksContent } from "@web-src/features/tasks/TasksContent";
import { TasksControlBar } from "@web-src/features/tasks/TasksControlBar";
import {
  TasksPageContextProvider,
  useTasksPageContext,
} from "@web-src/features/tasks/TasksPageContext";
import { useMarkModuleAsRead } from "@web-src/hooks/useMarkModuleAsRead";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { useMemo } from "react";

const InnerTasks = () => {
  const { tasksSource } = useTasksPageContext();
  const { entityId } = useEntitySelectedProvider();

  const { getCustomerDetailsById } = useCustomers({ entityId });

  const { generateUrl } = useNavigation();
  const { t } = useTranslations();

  const breadcrumbs = useMemo<HeaderBreadcrumbsItem[]>(() => {
    if (tasksSource.type === "customerTasks") {
      const customerDetails = getCustomerDetailsById(tasksSource.customerId);

      return [
        {
          title: t({ id: "tasks-page.customers", defaultMessage: "Customers" }),
          href: generateUrl("customersCustomers"),
        },
        {
          title:
            customerDetails?.fullName ||
            t({ id: "tasks-page.customer", defaultMessage: "Customer" }),
          href: generateUrl("customersTasks", {
            customerId: tasksSource.customerId,
          }),
        },
      ];
    }

    let tasksRootBreadcrumb: HeaderBreadcrumbsItem;

    if (tasksSource.boardId === "my") {
      tasksRootBreadcrumb = {
        title: t({
          id: "tasks-page.tasks",
          defaultMessage: "Tasks",
        }),
        href: generateUrl("tasksTasks", { boardId: "my" }),
      };
    } else if (tasksSource.boardId === "team") {
      tasksRootBreadcrumb = {
        title: t({
          id: "tasks-page.team-tasks",
          defaultMessage: "Team Tasks",
        }),
        href: generateUrl("tasksTasks", { boardId: "team" }),
      };
    } else {
      // TODO: This needs to be improved: board icon and name are missing
      tasksRootBreadcrumb = {
        title: "Board tasks",
        href: generateUrl("tasksTasks", { boardId: tasksSource.boardId }),
      };
    }

    return [tasksRootBreadcrumb];
  }, [tasksSource, generateUrl, getCustomerDetailsById, t]);

  useMarkModuleAsRead({
    entityId,
    module: "task",
    skip: tasksSource.type === "customerTasks",
  });

  return (
    <TaskFilteringProvider entityId={entityId}>
      <TaskSortingProvider>
        <TaskViewsProvider entityId={entityId} source={tasksSource}>
          <TasksPageLayout
            breadcrumbs={breadcrumbs}
            hideNotifications={tasksSource.type === "customerTasks"}
          >
            <TasksControlBar />
            <TasksContent />
          </TasksPageLayout>
        </TaskViewsProvider>
      </TaskSortingProvider>
    </TaskFilteringProvider>
  );
};

export const Tasks = () => (
  <TasksPageContextProvider>
    <InnerTasks />
  </TasksPageContextProvider>
);
