import {
  PlainButton,
  TextField,
  Alert,
} from "@jugl-web/ui-components/cross-platform";
import { Tooltip } from "@jugl-web/ui-components";
import { useState, useMemo, useEffect, useCallback } from "react";
import { cx, useTranslations, useToast } from "@jugl-web/utils";
import { ColorPickerPopover } from "@jugl-web/ui-components/web/ColorPickerPopover/ColorPickerPopover";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { BoardRoles, BoardTask } from "@jugl-web/rest-api/tasks";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { useForm, Controller } from "react-hook-form";
import { ReactComponent as DeleteIcon } from "./assets/delete.svg";
import { UserPicker } from "./components/userPicker/UserPicker";
import { DeleteBoardConfirmationDialog } from "./components/DeleteBoardConfirmationDialog";

export const TaskBoardDialog = ({
  isOpen,
  isReadonly,
  board,
  onClose,
}: {
  isOpen: boolean;
  isReadonly?: boolean;
  board?: BoardTask;
  onClose: () => void;
}) => {
  const { t } = useTranslations();
  const { entity } = useEntity();
  const { me } = useMe();
  const { tasksApi } = useRestApiProvider();
  const { toast } = useToast({ variant: "web" });

  const { register, handleSubmit, watch, reset, control, setValue } = useForm<{
    boardName: string;
    users: { id: string; type: BoardRoles }[];
    color: string;
  }>({
    mode: "onSubmit",
    defaultValues: {
      boardName: board?.name || "",
      users: board?.users.map((user) => ({
        id: user.user_id,
        type: user.type,
      })) || [
        {
          id: me?.id,
          type: "owner",
        },
      ],
      color: board?.color || "#F33A9E",
    },
  });

  const boardState = watch();

  const [createBoard, { isLoading: isCreatingInProgress }] =
    tasksApi.useCreateProjectBoardMutation();
  const [updateBoard, { isLoading: isUpdatingInProgress }] =
    tasksApi.useUpdateProjectBoardMutation();

  const [currentStep, setCurrentStep] = useState<"new" | "edit" | "delete">(
    "new"
  );

  const resetState = useCallback(() => {
    reset({
      boardName: board?.name || "",
      users: board?.users.map((user) => ({
        id: user.user_id,
        type: user.type,
      })) || [{ id: me?.id, type: "owner" }],
      color: board?.color || "#F33A9E",
    });
    setCurrentStep("new");
  }, [board?.color, board?.name, board?.users, me?.id, reset]);

  const handleCreateBoard = async () => {
    const users: { id: string; type: BoardRoles }[] = boardState.users.filter(
      (user) => user.id !== me?.id
    );

    const response = await createBoard({
      data: {
        name: boardState.boardName.trim(),
        users,
        color: boardState.color,
      },
      entityId: entity?.id || "",
    });
    if ("data" in response) {
      handleClose();
      toast(
        t({
          id: "feedback.new-board-was-created",
          defaultMessage: "New Board was created",
        })
      );
    }
  };

  const handleEditBoard = async () => {
    if (!board?.id) return;

    const response = await updateBoard({
      data: {
        name: boardState.boardName.trim(),
        color: boardState.color,
        users: boardState.users,
      },
      boardId: board.id,
      entityId: entity?.id || "",
    });
    if ("data" in response) {
      handleClose();
      toast(
        t({
          id: "feedback.new-board-was-updated",
          defaultMessage: "Board info was updated",
        })
      );
    }
  };

  const stepTitle = useMemo(() => {
    switch (currentStep) {
      case "new":
        return t({
          id: "tasks-board-dialog.new-board",
          defaultMessage: "New board",
        });
      case "edit":
        return t({
          id: "tasks-board-dialog.board-details",
          defaultMessage: "Board details",
        });
      case "delete":
        return t({
          id: "tasks-board-dialog.delete-board",
          defaultMessage: "Delete board",
        });
      default:
        return "";
    }
  }, [currentStep, t]);

  const handleClose = () => {
    onClose();
    resetState();
  };

  useEffect(() => {
    if (isOpen && board?.id) {
      setCurrentStep("edit");
    }
  }, [board?.id, isOpen]);

  useEffect(() => {
    if (isOpen && me?.id && currentStep === "new" && !board) {
      setValue("users", [
        {
          id: me?.id,
          type: "owner",
        },
      ]);
    }
  }, [board, currentStep, isOpen, me?.id, setValue]);

  if (!me?.id || !entity?.id) return null;

  if (currentStep === "delete" && board) {
    return (
      <DeleteBoardConfirmationDialog
        entityId={entity?.id || ""}
        board={board}
        isOpen={currentStep === "delete"}
        onClose={() => handleClose()}
      />
    );
  }

  return (
    <Alert
      className="z-50 w-[560px]"
      isOpen={isOpen && Boolean(me?.id)}
      onRequestClose={onClose}
      isCloseButtonVisible
      header={stepTitle}
      headerRightContent={
        <Tooltip
          placement="bottom"
          className="z-[999]"
          renderTrigger={({ props, ref }) => (
            <PlainButton
              className={cx(
                "hover:bg-tertiary-50 mr-6 flex h-8 w-8 items-center justify-center rounded-lg",
                {
                  hidden: isReadonly || currentStep !== "edit",
                }
              )}
              {...props}
              ref={ref}
              onClick={() => setCurrentStep("delete")}
            >
              <DeleteIcon />
            </PlainButton>
          )}
        >
          {t({
            id: "tasks-board-dialog.delete-board",
            defaultMessage: "Delete Board",
          })}
        </Tooltip>
      }
      buttonsContainerClassName="flex justify-center [&>button]:flex-none"
      buttons={[
        {
          className: "w-[300px]",
          isDisabled: isCreatingInProgress || isUpdatingInProgress,
          text: t({ id: "common.save", defaultMessage: "Save" }),
          color: "primary",
          onClick:
            currentStep === "edit"
              ? handleSubmit(handleEditBoard)
              : handleSubmit(handleCreateBoard),
        },
      ]}
      img={
        <Controller
          name="color"
          control={control}
          render={({ field: { onChange, value } }) => (
            <ColorPickerPopover
              title="Background color"
              color={value}
              placement="right-start"
              renderTrigger={({ Trigger, triggerRef }) => (
                <Trigger
                  ref={triggerRef}
                  as={PlainButton}
                  disabled={isReadonly}
                  className="font-secondary flex h-[80px] w-[80px] items-center justify-center rounded-[10.5px] text-[32px] text-white transition hover:brightness-50"
                  style={{ backgroundColor: boardState.color }}
                >
                  {boardState.boardName.charAt(0).toUpperCase().trim()}
                </Trigger>
              )}
              onColorChange={(color) => onChange(color)}
            />
          )}
        />
      }
      content={
        <div className="flex w-full flex-col items-center justify-center px-4">
          <div className="w-full">
            <TextField
              variant="underline"
              isFullWidth
              value={boardState.boardName}
              inputClassName="font-secondary text-dark text-2xl ml-0 pl-0 border-dark-100"
              labelClassName="text-dark-800 text-xs ml-0"
              placeholder={t({
                id: "common.enter",
                defaultMessage: "Enter",
              })}
              label={t({
                id: "tasks-board-dialog.board-name",
                defaultMessage: "Board name",
              })}
              {...register("boardName")}
            />
            <Controller
              name="users"
              control={control}
              render={({ field: { onChange, value } }) => (
                <UserPicker
                  entityId={entity?.id || ""}
                  meId={me.id}
                  users={value}
                  onChange={(members) => {
                    const usersState = boardState.users;
                    members.forEach((member) => {
                      const find = usersState.find(
                        (user) => user.id === member
                      );
                      if (find) {
                        find.type = "member";
                      }
                      if (!find) {
                        usersState.push({
                          id: member,
                          type: "member",
                        });
                      }
                    });
                    onChange(usersState);
                  }}
                  variant="member"
                />
              )}
            />

            <Controller
              name="users"
              control={control}
              render={({ field: { onChange, value } }) => (
                <UserPicker
                  entityId={entity?.id || ""}
                  meId={me.id}
                  users={value}
                  onChange={(owners) => {
                    const usersState = boardState.users;
                    owners.forEach((owner) => {
                      const find = usersState.find((user) => user.id === owner);
                      if (find) {
                        find.type = "owner";
                      }
                      if (!find) {
                        usersState.push({
                          id: owner,
                          type: "owner",
                        });
                      }
                    });
                    onChange(usersState);
                  }}
                  variant="owner"
                />
              )}
            />
          </div>
        </div>
      }
    />
  );
};
