import { useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import { TopBarButton, TopBarButtonProps } from "../TopBarButton";
import { ReactComponent as ManageFieldsIcon } from "./assets/manage-fields.svg";

export const ManageFieldsTopBarButton: FC<
  Pick<TopBarButtonProps, "onClick">
> = ({ onClick }) => {
  const { t } = useTranslations();

  return (
    <TopBarButton
      label={t({
        id: "tasks-page.manage-fields",
        defaultMessage: "Manage fields",
      })}
      icon={<ManageFieldsIcon />}
      onClick={onClick}
    />
  );
};
