import { useTaskFiltering } from "@jugl-web/domain-resources/tasks/components/TaskFilteringProvider";
import { useTaskFields } from "@jugl-web/domain-resources/tasks/hooks/useTaskFields";
import { getActiveFiltersCount } from "@jugl-web/domain-resources/tasks/hooks/useTaskFiltersState";
import { InternalTaskFilterSet } from "@jugl-web/rest-api/tasks";
import {
  Alert,
  Button,
  InteractiveContainer,
  Menu,
  PlainButton,
  Popover,
  PopoverProps,
  Radio,
} from "@jugl-web/ui-components/cross-platform";
import { cx, useAppVariant, useToast, useTranslations } from "@jugl-web/utils";
import { FC, useState } from "react";
import { ManageFilterSetDialog } from "../ManageFilterSetDialog";
import { useTasksPageContext } from "../TasksPageContext";
import { ReactComponent as MenuIcon } from "./assets/menu.svg";
import { ReactComponent as PlusIcon } from "./assets/plus.svg";
import { CollectiveFilterPills } from "./components/CollectiveFilterPills";

export interface TaskFiltersPopoverProps
  extends Pick<PopoverProps, "renderTrigger"> {
  entityId: string;
  onOpenFiltersDialog: () => void;
}

export const TaskFiltersPopover: FC<TaskFiltersPopoverProps> = ({
  entityId,
  renderTrigger,
  onOpenFiltersDialog,
}) => {
  const [isManageFilterSetDialogOpen, setIsManageFilterSetDialogOpen] =
    useState(false);

  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] =
    useState(false);

  const [bufferedFilterSet, setBufferedFilterSet] =
    useState<InternalTaskFilterSet | null>(null);

  const { filtering, activeFiltersCount, changeFiltering, resetFiltering } =
    useTaskFiltering();

  const { filterSets, deleteFilterSet, isDeletingFilterSet } = useTaskFields({
    entityId,
  });

  const { tasksSource } = useTasksPageContext();

  const { variant } = useAppVariant();
  const { toast } = useToast({ variant });
  const { t } = useTranslations();

  const hasActiveStandardFilters =
    filtering.type === "standard" && activeFiltersCount > 0;

  const handleFilterSetClick = (filterSet: InternalTaskFilterSet) => {
    if (
      filtering.type === "filter-set" &&
      filtering.filterSetId === filterSet.id
    ) {
      resetFiltering();
      return;
    }

    changeFiltering({ type: "filter-set", filterSetId: filterSet.id });
  };

  const handleDeleteFilterSet = async (filterSetId: string) => {
    try {
      await deleteFilterSet(filterSetId);

      setIsDeleteConfirmationDialogOpen(false);

      toast(
        t({
          id: "feedback.filter-set-deleted",
          defaultMessage: "Filter set has been removed",
        })
      );
    } catch {
      // Do nothing
    }
  };

  return (
    <>
      <Popover
        placement="bottom-start"
        className="w-[400px]"
        renderTrigger={renderTrigger}
      >
        {({ onClose }) => (
          <div className="flex flex-col">
            <div className="flex flex-col gap-6 px-[18px] py-6">
              <div className="flex items-center justify-between gap-4">
                <div className="flex flex-col gap-0.5">
                  <span className="font-secondary text-dark font-medium leading-4 tracking-[0.16px]">
                    {t({
                      id: "tasks-page.applied-filters",
                      defaultMessage: "Applied filters",
                    })}
                  </span>
                  <span className="leading-2 text-xs tracking-[0.12px] text-[#828282]">
                    {hasActiveStandardFilters
                      ? t(
                          {
                            id: "tasks-page.filters-applied-count",
                            defaultMessage:
                              "{count} {count, plural, one {filter} other {filters}} applied",
                          },
                          { count: activeFiltersCount }
                        )
                      : t({
                          id: "tasks-page.no-filters-applied",
                          defaultMessage: "No filters applied",
                        })}
                  </span>
                </div>
                <div className="flex items-center gap-3">
                  {hasActiveStandardFilters ? (
                    <>
                      <Button
                        variant="contained"
                        color="grey"
                        className="h-8 font-normal"
                        onClick={() => {
                          resetFiltering();
                          onClose();
                        }}
                      >
                        {t({ id: "common.clear", defaultMessage: "Clear" })}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        className="h-8"
                        onClick={() => {
                          onOpenFiltersDialog();
                          onClose();
                        }}
                      >
                        {t({ id: "common.edit", defaultMessage: "Edit" })}
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      className="h-8"
                      onClick={() => {
                        onOpenFiltersDialog();
                        onClose();
                      }}
                    >
                      {t({
                        id: "tasks-page.add-filters",
                        defaultMessage: "Add filters",
                      })}
                    </Button>
                  )}
                </div>
              </div>
              {hasActiveStandardFilters && (
                <CollectiveFilterPills
                  entityId={entityId}
                  onMorePillClick={() => {
                    onOpenFiltersDialog();
                    onClose();
                  }}
                />
              )}
            </div>
            {!tasksSource.isCustomerTasks && (
              <>
                <div className="px-[18px]">
                  <div className="border-b-dark-100 border-t-0 border-r-0 border-l-0 border-b border-solid" />
                </div>
                <div className="flex flex-col gap-6 py-6">
                  <div className="flex items-center justify-between gap-4 px-[18px]">
                    <div className="flex flex-col gap-0.5">
                      <span className="font-secondary text-dark font-medium leading-4 tracking-[0.16px]">
                        {t({
                          id: "tasks-page.my-filter-sets",
                          defaultMessage: "My filter sets",
                        })}
                      </span>
                      <span className="leading-2 text-xs tracking-[0.12px] text-[#828282]">
                        {t({
                          id: "tasks-page.filter-sets-hint",
                          defaultMessage:
                            "Apply filters and save them as sets for daily usage ✨",
                        })}
                      </span>
                    </div>
                    <Button
                      variant="contained"
                      color="grey"
                      className="flex h-8 w-8 shrink-0 items-center justify-center p-0"
                      onClick={() => {
                        setBufferedFilterSet(null);
                        setIsManageFilterSetDialogOpen(true);
                        onClose();
                      }}
                    >
                      <PlusIcon className="shrink-0" />
                    </Button>
                  </div>
                  {filterSets.length > 0 && (
                    <div className="jugl__custom-scrollbar flex max-h-[308px] flex-col gap-4 overflow-auto px-[18px]">
                      {filterSets.map((filterSet) => {
                        const isActive =
                          filtering.type === "filter-set" &&
                          filtering.filterSetId === filterSet.id;

                        return (
                          <InteractiveContainer
                            key={filterSet.id}
                            className={cx(
                              "flex h-[65px] shrink-0 items-center justify-between gap-2 rounded-lg px-3 transition-colors",
                              isActive
                                ? "bg-primary-50 hover:bg-[#D4EDFF]"
                                : "bg-grey-100 hover:bg-[#F7F7F8]"
                            )}
                            onClick={() => handleFilterSetClick(filterSet)}
                          >
                            <div className="flex items-start gap-2 overflow-hidden">
                              <div className="h-[21px]">
                                <Radio
                                  readOnly
                                  tabIndex={-1}
                                  isChecked={isActive}
                                />
                              </div>
                              <div className="flex flex-col gap-0.5 overflow-hidden">
                                <span className="text-dark truncate text-sm leading-[21px] tracking-[0.14px]">
                                  {filterSet.name}
                                </span>
                                <span className="leading-2 text-xs tracking-[0.12px] text-[#828282]">
                                  {t(
                                    {
                                      id: "tasks-page.set-based-on-filters-count",
                                      defaultMessage:
                                        "Based on {count} {count, plural, one {filter} other {filters}}",
                                    },
                                    {
                                      count: getActiveFiltersCount(
                                        filterSet.filters
                                      ),
                                    }
                                  )}
                                </span>
                              </div>
                            </div>
                            <Menu
                              placement="bottom-start"
                              renderTrigger={({
                                Trigger,
                                triggerRef,
                                isOpen,
                              }) => (
                                <Trigger
                                  ref={triggerRef}
                                  as={PlainButton}
                                  className={cx(
                                    "hover:bg-grey/5 rounded-full p-2 transition-colors",
                                    isOpen && "bg-grey/5"
                                  )}
                                  onClick={(event) => event.stopPropagation()}
                                >
                                  <MenuIcon />
                                </Trigger>
                              )}
                              sections={[
                                [
                                  {
                                    id: "edit",
                                    label: t({
                                      id: "tasks-page.edit-set",
                                      defaultMessage: "Edit set",
                                    }),
                                    onSelect: (_, close) => {
                                      setBufferedFilterSet(filterSet);
                                      setIsManageFilterSetDialogOpen(true);
                                      close();
                                    },
                                  },
                                  {
                                    id: "delete",
                                    label: t({
                                      id: "tasks-page.delete-set",
                                      defaultMessage: "Delete set",
                                    }),
                                    onSelect: (_, close) => {
                                      setBufferedFilterSet(filterSet);
                                      setIsDeleteConfirmationDialogOpen(true);
                                      close();
                                    },
                                  },
                                ],
                              ]}
                              className="z-[110]"
                            />
                          </InteractiveContainer>
                        );
                      })}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </Popover>
      <ManageFilterSetDialog
        entityId={entityId}
        isOpen={isManageFilterSetDialogOpen}
        editingFilterSet={bufferedFilterSet}
        onClose={() => setIsManageFilterSetDialogOpen(false)}
      />
      <Alert
        isOpen={isDeleteConfirmationDialogOpen}
        title={t({
          id: "tasks-page.delete-filter-set-dialog-title",
          defaultMessage: "Delete filter set",
        })}
        content={t(
          {
            id: "tasks-page.delete-filter-set-dialog-description",
            defaultMessage:
              "Are you sure you want to delete filter set {name}?",
          },
          {
            name: (
              <span className="text-primary-800 font-semibold">
                {bufferedFilterSet?.name}
              </span>
            ),
          }
        )}
        buttons={[
          {
            text: t({ id: "common.cancel", defaultMessage: "Cancel" }),
            role: "close",
          },
          {
            text: t({ id: "common.delete", defaultMessage: "Delete" }),
            color: "tertiary",
            isDisabled: isDeletingFilterSet,
            onClick: () => handleDeleteFilterSet(bufferedFilterSet?.id || ""),
          },
        ]}
        onRequestClose={() => setIsDeleteConfirmationDialogOpen(false)}
      />
    </>
  );
};
