import { useRestApiProvider } from "@jugl-web/rest-api";
import { useToast, useTranslations } from "@jugl-web/utils";
import { FC, useState, useRef, useEffect } from "react";
import { BoardTask } from "@jugl-web/rest-api/tasks";
import { Alert, TextField } from "@jugl-web/ui-components/cross-platform";
import { ReactComponent as WarningIcon } from "./assets/warning.svg";

interface DeleteBoardConfirmationDialogProps {
  isOpen: boolean;
  board: BoardTask;
  entityId: string;
  onClose: () => void;
}

export const DeleteBoardConfirmationDialog: FC<
  DeleteBoardConfirmationDialogProps
> = ({ isOpen, entityId, board, onClose }) => {
  const { t } = useTranslations();
  const { toast } = useToast({ variant: "web" });

  const [repeatedEntityName, setRepeatedEntityName] = useState("");
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (isOpen) {
      setRepeatedEntityName("");
      window.setTimeout(() => inputRef.current?.focus());
    }
  }, [isOpen]);

  const { tasksApi } = useRestApiProvider();
  const { data: taskNumberByBoard } = tasksApi.useGetBoardTasksNumberQuery({
    entityId,
    boardId: board.id,
  });
  const [deleteBoard, { isLoading: isDeleteInProgress }] =
    tasksApi.useDeleteProjectBoardMutation();

  const handleConfirm = async () => {
    if (!board) {
      return;
    }
    const response = await deleteBoard({
      entityId,
      boardId: board.id,
    });

    if ("data" in response) {
      toast(
        t({
          id: "feedback.board-was-removed-from-workspace",
          defaultMessage: "Board was removed from Workspace",
        })
      );

      onClose();
    }
  };

  return (
    <Alert
      isOpen={isOpen}
      onRequestClose={onClose}
      isCloseButtonVisible
      header={t({
        id: "common.confirm-action",
        defaultMessage: "Confirm action",
      })}
      title={t({
        id: "tasks-board-dialog.delete-board",
        defaultMessage: "Delete board",
      })}
      img={
        <div
          style={{ backgroundColor: board?.color || "#F33A9E" }}
          className="font-secondary relative flex h-[80px] w-[80px] items-center justify-center rounded-[10.5px] text-[32px] text-white transition"
        >
          {board?.name?.charAt(0).toUpperCase()}
          <WarningIcon className="absolute -right-[20px] -bottom-[20px]" />
        </div>
      }
      content={
        <div className="text-dark-800">
          {t(
            {
              id: "tasks-board-dialog.confirm-delete-board",
              defaultMessage:
                "Do you really want to delete board <b>{boardName}</b>? <b2>{count} {count, plural, one {Task} other {Tasks}} will be removed</b2>. To confirm Action, type the name of the Board you are trying to delete",
            },
            {
              boardName: board?.name || "test",
              b: (text: (string | JSX.Element)[]) => (
                <span className="text-primary-800 text-semibold">{text}</span>
              ),
              count: taskNumberByBoard,
              b2: (text: (string | JSX.Element)[]) => (
                <span className="text-gradients-danger text-semibold">
                  {text}
                </span>
              ),
            }
          )}
          <TextField
            className="mt-8"
            ref={inputRef}
            value={repeatedEntityName}
            placeholder={t({
              id: "tasks-board.dialog.delete-board-input-placeholder",
              defaultMessage: "Enter board name",
            })}
            isFullWidth
            onChange={(event) => setRepeatedEntityName(event.target.value)}
          />
        </div>
      }
      buttons={[
        {
          text: t({
            id: "task-board-dialog.delete-board",
            defaultMessage: "Delete board",
          }),
          color: "tertiary",
          onClick: handleConfirm,
          isDisabled: repeatedEntityName !== board.name || isDeleteInProgress,
        },
      ]}
    />
  );
};
