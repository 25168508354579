import { useRestApiProvider } from "@jugl-web/rest-api";
import { TasksSource } from "@jugl-web/rest-api/tasks";

export type UseTasksQueryOptions = {
  entityId: string;
  source: TasksSource;
};

export const useTasksQuery = ({ entityId, source }: UseTasksQueryOptions) => {
  const { tasksApi } = useRestApiProvider();

  const tasksQuery = tasksApi.useGetTasksQuery(
    { entityId, source },
    {
      // We refetch task collections other than My Tasks,
      // because only My Tasks support live updates at this moment
      refetchOnMountOrArgChange: !(
        source.type === "boardTasks" && source.boardId === "my"
      ),
    }
  );

  const { data, isLoading, isError, refetch } = tasksQuery;

  return {
    data,
    isLoading,
    isError,
    refetch,
  };
};
