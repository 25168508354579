import { ReactNode } from "react";
import { Tooltip, PlainButton } from "@jugl-web/ui-components";
import { cx } from "@jugl-web/utils";

export const IconButton = ({
  tooltipText,
  icon,
  isActive,
  onClick,
}: {
  tooltipText: ReactNode;
  icon: ReactNode;
  isActive?: boolean;
  onClick?: () => void;
}) => (
  <Tooltip
    renderTrigger={({ ref, props }) => (
      <PlainButton
        ref={ref}
        {...props}
        onClick={onClick}
        className={cx(
          "easy-out h-8 w-8 rounded-lg transition-colors duration-300 hover:bg-[#1A75D2]",
          {
            "bg-[#1A75D2]": isActive,
          }
        )}
      >
        {icon}
      </PlainButton>
    )}
  >
    <span className="font-secondary text-sm">{tooltipText}</span>
  </Tooltip>
);
