import { useDroppable } from "@dnd-kit/core";
import { Popover, PopoverButton } from "@headlessui/react";
import {
  TASK_COLUMNS_IDS,
  TaskColumnDroppableData,
} from "@jugl-web/domain-resources/tasks";
import {
  TASK_CALENDAR_PILL_HEIGHT,
  TaskCalendarPill,
  TaskCalendarPillBase,
  useTaskCardAdapter,
} from "@jugl-web/domain-resources/tasks/components/TaskCard";
import { useTaskFiltering } from "@jugl-web/domain-resources/tasks/components/TaskFilteringProvider";
import { PreviewTask } from "@jugl-web/rest-api/tasks";
import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform";
import {
  assignRefs,
  cx,
  useResizeObserver,
  useTranslations,
} from "@jugl-web/utils";
import { NewTaskPopover } from "@web-src/features/tasks/TasksControlBar/components/NewTaskPopover";
import { useTasksPageContext } from "@web-src/features/tasks/TasksPageContext";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { endOfDay, isToday } from "date-fns";
import { FC, useMemo, useRef, useState } from "react";
import { MONTH_DAY_CELL_PILLS_GAP } from "../../consts";
import { CalendarMonthDayExpandableCellPanel } from "../CalendarMonthDayExpandableCellPanel";
import { CalendarViewLayout } from "../CalendarViewLayout";

interface CalendarMonthDayCellProps {
  cellIndex: number;
  cellDate: Date;
  calendarDate: Date;
  tasks: PreviewTask[];
  onTaskClick: (task: PreviewTask) => void;
}

export const CalendarMonthDayCell: FC<CalendarMonthDayCellProps> = ({
  cellIndex,
  cellDate,
  calendarDate,
  tasks,
  onTaskClick,
}) => {
  const [isCellExpanded, setIsCellExpanded] = useState(false);
  const [taskListHeight, setTaskListHeight] = useState(0);

  const cellRef = useRef<HTMLButtonElement>(null);

  const { entityId } = useEntitySelectedProvider();
  const { searchQuery, hasActiveFilter } = useTaskFiltering();
  const { tasksSource } = useTasksPageContext();

  const { getTaskCardProps } = useTaskCardAdapter({
    entityId,
    source: tasksSource,
  });

  const { t } = useTranslations();

  const columnId = TASK_COLUMNS_IDS.DATE_VIEW(cellDate);

  const { isOver, setNodeRef } = useDroppable({
    id: columnId,
    data: {
      type: "dueDate",
      value: endOfDay(cellDate).toISOString(),
    } satisfies TaskColumnDroppableData | undefined,
  });

  const { ref: taskListRef } = useResizeObserver({
    onResize: (entry) => {
      const size = entry.borderBoxSize[0];

      if (size) {
        setTaskListHeight(size.blockSize);
      }
    },
  });

  const pillsCountFittingInCell = Math.max(
    Math.floor(
      taskListHeight / (TASK_CALENDAR_PILL_HEIGHT + MONTH_DAY_CELL_PILLS_GAP)
    ),
    1
  );

  const moreTasksCount = tasks.length - pillsCountFittingInCell + 1;

  const visibleTasks = useMemo(
    () => tasks.slice(0, pillsCountFittingInCell),
    [pillsCountFittingInCell, tasks]
  );

  const shouldShowNoResultsMessage =
    tasks.length === 0 && (hasActiveFilter || searchQuery.length > 0);

  const isCellInCurrentMonth = cellDate.getMonth() === calendarDate.getMonth();
  const isCellToday = isToday(cellDate);
  const isCellInteractive = !isCellExpanded;

  return (
    <NewTaskPopover
      entityId={entityId}
      initialState={{ dueDate: { date: endOfDay(cellDate) } }}
      taskPropertiesPanelConfigOverrides={{
        dueDate: { isDateEditable: false },
      }}
      placement="bottom"
      isDisabled={!isCellInteractive}
      renderTrigger={({ Trigger, triggerRef, isOpen }) => (
        <Trigger
          ref={(element) => {
            setNodeRef(element);
            assignRefs([triggerRef, cellRef])(element);
          }}
          as={InteractiveContainer}
          isDisabled={!isCellInteractive}
          className={cx(
            "relative flex flex-col items-center gap-2 border border-solid border-transparent bg-white px-3 pt-1.5 pb-2.5 transition-colors",
            "focus-visible:border-primary-200 focus:outline-none",
            isOpen && "border-primary-200",
            isCellInteractive && "hover:border-primary-200",
            isOver && "bg-[#C3D2F8]"
          )}
        >
          <div
            className={cx(
              "flex h-6 w-6 shrink-0 items-center justify-center rounded-full bg-white text-xs font-medium",
              isCellToday
                ? "bg-primary text-white"
                : isCellInCurrentMonth
                ? "text-dark-800"
                : "text-[#949DA1]"
            )}
          >
            {cellDate.getDate()}
          </div>
          <div
            ref={taskListRef}
            className="flex w-full grow flex-col"
            style={{ gap: MONTH_DAY_CELL_PILLS_GAP }}
          >
            {shouldShowNoResultsMessage && (
              <CalendarViewLayout.EmptyStateMessage>
                {t({
                  id: "common.no-results",
                  defaultMessage: "No results",
                })}
              </CalendarViewLayout.EmptyStateMessage>
            )}
            {visibleTasks.map((task, index) => {
              const isLast = index === visibleTasks.length - 1;

              if (isLast && moreTasksCount > 1) {
                return (
                  <Popover key="more-tasks-pill">
                    <PopoverButton
                      as={TaskCalendarPillBase}
                      className="text-dark-700 bg-grey-100 hover:bg-grey-200"
                      onClick={(event) => event.stopPropagation()}
                    >
                      {t(
                        {
                          id: "tasks-page.more-tasks-with-count",
                          defaultMessage: "+{count} more tasks",
                        },
                        { count: moreTasksCount }
                      )}
                    </PopoverButton>
                    <CalendarMonthDayExpandableCellPanel
                      cellDate={cellDate}
                      cellIndex={cellIndex}
                      tasks={tasks}
                      onTaskClick={onTaskClick}
                      onInternalOpenStateChange={setIsCellExpanded}
                    />
                  </Popover>
                );
              }

              return (
                <TaskCalendarPill
                  key={task.id}
                  highlightedText={searchQuery}
                  onClick={(event) => {
                    event.stopPropagation();
                    onTaskClick(task);
                  }}
                  columnId={columnId}
                  draggable
                  {...getTaskCardProps(task)}
                >
                  {task.name}
                </TaskCalendarPill>
              );
            })}
          </div>
        </Trigger>
      )}
    />
  );
};
