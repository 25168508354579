import { TaskCalendarView } from "@jugl-web/domain-resources/tasks";
import { TaskPropertiesPanelConfig } from "@jugl-web/domain-resources/tasks/components/TaskPropertiesPanel/types";
import { TaskFormState } from "@jugl-web/domain-resources/tasks/hooks/useTaskFormState";
import { PreviewTask, TasksSource } from "@jugl-web/rest-api/tasks";
import { HookOutOfContextError } from "@jugl-web/utils";
import {
  useLocalStorage,
  useSessionStorage,
} from "@jugl-web/utils/hooks/useStorage";
import {
  TASK_CALENDAR_DATE_KEY,
  TASK_CALENDAR_VIEW_KEY,
  TASK_LIST_LAYOUT_KEY,
} from "@jugl-web/utils/storage";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";

export type ViewMode =
  | "date-view"
  | "labels-view"
  | "priority-view"
  | "status-view"
  | "custom-field-view";

export type SupportedTaskPropertiesPanelConfigOverrides = {
  dueDate?: Partial<TaskPropertiesPanelConfig["dueDate"]>;
  assignees?: Partial<TaskPropertiesPanelConfig["assignees"]>;
};

export interface NewTaskDialogState {
  isOpen: boolean;
  initialFormState?: Partial<TaskFormState>;
  taskPropertiesPanelConfigOverrides?: SupportedTaskPropertiesPanelConfigOverrides;
}

interface FutureTaskDialogState {
  isOpen: boolean;
  task: PreviewTask | null;
}

export type TaskListLayout = "kanban" | "table" | "calendar";

/**
 * Tasks source interface with derived flags just for convenience
 */
type EnhancedTasksSource = TasksSource & {
  isMyTasks: boolean;
  isTeamTasks: boolean;
  isUserBoardTasks: boolean;
  isCustomerTasks: boolean;
};

interface TasksPageContextValue {
  newTaskDialogState: NewTaskDialogState;
  futureTaskDialogState: FutureTaskDialogState;
  tasksSource: EnhancedTasksSource;
  taskListLayout: TaskListLayout;
  taskCalendarView: TaskCalendarView;
  taskCalendarDate: Date;
  setTaskListLayout: (layout: TaskListLayout) => void;
  setTaskCalendarView: (view: TaskCalendarView) => void;
  setTaskCalendarDate: (date: Date) => void;
  navigateToTaskDetailsPage: (taskId: string) => void;
  openNewTaskDialog: (state: Omit<NewTaskDialogState, "isOpen">) => void;
  openFutureTaskDialog: (task: PreviewTask) => void;
  closeNewTaskDialog: () => void;
  closeFutureTaskDialog: () => void;
}

export const TasksPageContext = createContext<TasksPageContextValue | null>(
  null
);

interface TasksPageContextProviderProps {
  children: ReactNode;
}

export const TasksPageContextProvider: FC<TasksPageContextProviderProps> = ({
  children,
}) => {
  const { boardId, customerId } = useParams();

  const tasksSource = useMemo<EnhancedTasksSource>(() => {
    const source: TasksSource = (() => {
      if (customerId) {
        return { type: "customerTasks", customerId };
      }

      return { type: "boardTasks", boardId: boardId || "my" };
    })();

    const isMyTasks = source.type === "boardTasks" && source.boardId === "my";
    const isTeamTasks =
      source.type === "boardTasks" && source.boardId === "team";
    const isUserBoardTasks =
      source.type === "boardTasks" && !isMyTasks && !isTeamTasks;
    const isCustomerTasks = source.type === "customerTasks";

    return {
      ...source,
      isMyTasks,
      isTeamTasks,
      isUserBoardTasks,
      isCustomerTasks,
    };
  }, [boardId, customerId]);

  const { navigateToPage } = useNavigation();

  const [newTaskDialogState, setNewTaskDialogState] =
    useState<NewTaskDialogState>({ isOpen: false });

  const [futureTaskDialogState, setFutureTaskDialogState] =
    useState<FutureTaskDialogState>({ isOpen: false, task: null });

  const [taskListLayout, setTaskListLayout] = useLocalStorage<TaskListLayout>(
    TASK_LIST_LAYOUT_KEY,
    "kanban"
  );

  const [taskCalendarView, setTaskCalendarView] =
    useLocalStorage<TaskCalendarView>(TASK_CALENDAR_VIEW_KEY, "week");

  const sourceBasedTaskCalendarView = useMemo<TaskCalendarView>(() => {
    if (tasksSource.isTeamTasks) {
      return "week";
    }

    return taskCalendarView;
  }, [taskCalendarView, tasksSource]);

  const navigateToTaskDetailsPage = useCallback(
    (taskId: string) => {
      if (tasksSource.type === "customerTasks") {
        navigateToPage("customersTasksDetails", {
          customerId: tasksSource.customerId,
          taskId,
        });
      } else {
        navigateToPage("tasksDetails", { taskId });
      }
    },
    [navigateToPage, tasksSource]
  );

  const [taskCalendarDateString, setTaskCalendarDateString] =
    useSessionStorage<string>(TASK_CALENDAR_DATE_KEY, new Date().toJSON());

  const taskCalendarDate = useMemo(
    () => new Date(taskCalendarDateString),
    [taskCalendarDateString]
  );

  const setTaskCalendarDate = useCallback(
    (date: Date) => setTaskCalendarDateString(date.toJSON()),
    [setTaskCalendarDateString]
  );

  const openNewTaskDialog = (state: Omit<NewTaskDialogState, "isOpen">) => {
    setNewTaskDialogState({ isOpen: true, ...state });
  };

  const closeNewTaskDialog = () => {
    setNewTaskDialogState((previousState) => ({
      ...previousState,
      isOpen: false,
    }));
  };

  const openFutureTaskDialog = (task: PreviewTask) => {
    setFutureTaskDialogState({ isOpen: true, task });
  };

  const closeFutureTaskDialog = () => {
    setFutureTaskDialogState((previousState) => ({
      ...previousState,
      isOpen: false,
    }));
  };

  const contextValue = useMemo<TasksPageContextValue>(
    () => ({
      newTaskDialogState,
      futureTaskDialogState,
      tasksSource,
      taskListLayout,
      taskCalendarView: sourceBasedTaskCalendarView,
      taskCalendarDate,
      navigateToTaskDetailsPage,
      setTaskListLayout,
      setTaskCalendarView,
      setTaskCalendarDate,
      openNewTaskDialog,
      openFutureTaskDialog,
      closeNewTaskDialog,
      closeFutureTaskDialog,
    }),
    [
      newTaskDialogState,
      futureTaskDialogState,
      tasksSource,
      taskListLayout,
      sourceBasedTaskCalendarView,
      taskCalendarDate,
      navigateToTaskDetailsPage,
      setTaskListLayout,
      setTaskCalendarView,
      setTaskCalendarDate,
    ]
  );

  return (
    <TasksPageContext.Provider value={contextValue}>
      {children}
    </TasksPageContext.Provider>
  );
};

export const useTasksPageContext = () => {
  const context = useContext(TasksPageContext);

  if (!context) {
    throw new HookOutOfContextError("useTasksPageContext", "TasksPageContext");
  }

  return context;
};
