import React, { Fragment } from "react";
import { Link, To } from "react-router-dom";
import { cx } from "@jugl-web/utils";
import { ReactComponent as ChevronRightIcon } from "./assets/chevron-right.svg";

export interface HeaderBreadcrumbsItem {
  title: string;
  href?: To;
  isHidden?: boolean;
  onClick?: () => void;
}

export interface HeaderBreadcrumbsProps {
  icon?: React.ReactElement;
  items: HeaderBreadcrumbsItem[];
  rightContent?: React.ReactElement;
  disableShadow?: boolean;
}

export const HEADER_BREADCRUMBS_HEIGHT_PX = 60;

export const HeaderBreadcrumbs: React.FC<HeaderBreadcrumbsProps> = ({
  icon,
  items,
  rightContent,
  disableShadow,
}) => (
  <div
    className="relative z-50 flex w-full select-none items-center justify-between bg-white px-8"
    style={{
      height: HEADER_BREADCRUMBS_HEIGHT_PX,
      boxShadow: disableShadow
        ? undefined
        : "2px -6px 24px rgba(18, 22, 34, 0.16)",
    }}
  >
    <div className="flex items-center">
      {icon && <span className="mr-5 flex items-center">{icon}</span>}
      {items.map((item, index) => {
        if (item.isHidden) {
          return null;
        }

        const isLast = index === items.length - 1;

        return (
          <Fragment key={+index}>
            {index > 0 && <ChevronRightIcon className="mx-4" />}
            <div
              className={cx(
                "text-dark font-secondary text-lg font-normal leading-5",
                {
                  "cursor-pointer": !!item.onClick,
                  "font-medium": isLast,
                }
              )}
              onClick={item.onClick}
            >
              {item.href ? (
                <Link to={item.href} className="text-inherit no-underline">
                  {item.title}
                </Link>
              ) : (
                item.title
              )}
            </div>
          </Fragment>
        );
      })}
    </div>
    <div className="flex flex-1 justify-end">{rightContent}</div>
  </div>
);
