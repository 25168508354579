import { addSearchParamsToURL } from "@jugl-web/utils";
import { useEntityProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useCallback } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import {
  RootPagesNavigationParams,
  rootNavigationPageConfig,
} from "../root-config";
import { getEntityPagePath } from "../utils/getEntityPagePath";

export type NavigationPage = keyof RootPagesNavigationParams;

export type NavigateFnConfig<TPage extends NavigationPage> = {
  queryParams?: RootPagesNavigationParams[TPage]["queryParams"];
  replacePathOnly?: boolean;
  entityId?: string;
  replace?: boolean;
};

export type ConditionalPageArgs<TPage extends NavigationPage> =
  RootPagesNavigationParams[TPage]["pathParams"] extends never
    ? [undefined?, NavigateFnConfig<TPage>?]
    : [
        RootPagesNavigationParams[TPage]["pathParams"],
        NavigateFnConfig<TPage>?
      ];

type PageWithParametersFn<TReturnType> = <TPage extends NavigationPage>(
  page: TPage,
  ...args: ConditionalPageArgs<TPage>
) => TReturnType;

export const useNavigation = () => {
  const { entity } = useEntityProvider();
  const reactRouterNavigate = useNavigate();

  const generateUrl = useCallback<PageWithParametersFn<string>>(
    (page, ...args) => {
      const [pathParams, params] = args;
      const pageConfig = rootNavigationPageConfig[page];

      let pathToNavigate = generatePath(
        pageConfig.isPrivate && !pageConfig.isEntityOptional
          ? getEntityPagePath(pageConfig.path)
          : pageConfig.path || "",
        {
          ...(pathParams || {}),
          entityId: params?.entityId || entity?.id || null,
        }
      );

      if (params?.queryParams) {
        pathToNavigate = addSearchParamsToURL(
          pathToNavigate,
          params.queryParams
        );
      }

      return pathToNavigate;
    },
    [entity?.id]
  );

  const navigateBack = useCallback(
    () => reactRouterNavigate(-1),
    [reactRouterNavigate]
  );

  const navigate = useCallback<PageWithParametersFn<void>>(
    (page, ...args) => {
      const [, params] = args;
      const url = generateUrl(page, ...args);

      if (params?.replacePathOnly) {
        window.history.pushState(undefined, "", url);
        return;
      }

      reactRouterNavigate(url, { replace: params?.replace });
    },
    [generateUrl, reactRouterNavigate]
  );

  return {
    // Alias is used to differentiate between the two navigate functions
    // while we are refactoring the navigation
    navigateToPage: navigate,
    generateUrl,
    navigateBack,
  };
};
