import { useEntityProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { FC, useMemo } from "react";
import { apiUTCToLocalDateTime, cx } from "@jugl-web/utils";
import { EntityParticipantRole } from "@jugl-web/rest-api";
import differenceInDays from "date-fns/differenceInDays";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { ReactComponent as StarsIcon } from "./assets/stars.svg";

export const EntitySubscriptionInfoItem: FC<{
  isSideBarExpanded: boolean;
}> = ({ isSideBarExpanded }) => {
  const { entity, subscriptionInfo } = useEntityProvider();
  const { navigateToPage } = useNavigation();
  const text = useMemo(() => {
    if (!subscriptionInfo || subscriptionInfo.status === "free") {
      return null;
    }
    const planTillDays =
      differenceInDays(
        apiUTCToLocalDateTime(subscriptionInfo.planTill),
        new Date()
      ) || 1;
    const graceTillDays =
      differenceInDays(
        apiUTCToLocalDateTime(subscriptionInfo.graceTill),
        new Date()
      ) || 1;
    if (subscriptionInfo?.isInGrace) {
      return `Grace Period ends in ${graceTillDays} day${
        graceTillDays !== 1 ? "s" : ""
      }`;
    }
    if (subscriptionInfo?.isInTrial) {
      return `Trial ends in ${planTillDays} day${
        planTillDays !== 1 ? "s" : ""
      }`;
    }
    return null;
  }, [subscriptionInfo]);

  if (!text || !entity) {
    return null;
  }

  const handleClick = () => {
    if (entity.role !== EntityParticipantRole.admin) {
      return;
    }
    navigateToPage("workspaceSubscription");
  };

  return (
    <div className="bg-primary-700 p-2">
      <div
        className={cx(
          "flex cursor-pointer items-center rounded-lg bg-[#1A64D2] px-[10px] py-3 hover:brightness-95",
          {
            "flex-col gap-1": !isSideBarExpanded,
            "gap-2": isSideBarExpanded,
          }
        )}
        onClick={handleClick}
      >
        <StarsIcon />
        <div
          className={cx("text-sm font-medium text-white", {
            "text-[13px] leading-[150%]": !isSideBarExpanded,
          })}
        >
          {isSideBarExpanded ? text : ""}
        </div>
      </div>
    </div>
  );
};
