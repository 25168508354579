import {
  Avatar,
  InteractiveContainer,
  Menu,
} from "@jugl-web/ui-components/cross-platform";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { useTranslations } from "@jugl-web/utils";
import { useIntercom } from "@web-src/components/IntercomProvider";
import { EntitiesListProvider } from "@web-src/features/entities/providers/EntitiesListProvider/EntitiesListProvider";
import { ReactComponent as LogoIcon } from "./assets/logo.svg";
import { useMe } from "../../hooks/useMe";

import { ReactComponent as SettingsIcon } from "./assets/settings.svg";
import { IconButton } from "./components/IconButton";
import { ReactComponent as HelpIcon } from "./assets/gethelp.svg";
// import { ReactComponent as WorkspaceUpdatesIcon } from "./assets/updates.svg";
import { ReactComponent as HelpCenterIcon } from "./assets/help-center.svg";
import { ReactComponent as SupportChatIcon } from "./assets/support-chat.svg";
import { SelectEntityPopover } from "./components/SelectEntityPopover/SelectEntityPopover";

export const MainNavBar = () => {
  const { me } = useMe();
  const { navigateToPage } = useNavigation();
  const { t } = useTranslations();
  const { toggle: toggleIntercomWidget } = useIntercom();
  const locationRoute = window.location.pathname.split("/")[2] || "";
  const locationTab = window.location.pathname.split("/")[3] || "";

  return (
    <EntitiesListProvider>
      <div className="bg-primary-800 h-[48px]">
        <div className="flex h-[48px] w-full items-center justify-between pl-6 pr-8">
          <div className="flex items-center">
            <LogoIcon />
            <SelectEntityPopover />
          </div>
          <span className="flex gap-4">
            {/* <IconButton
            tooltipText={t({
              id: "main-navbar-component.workspace-updates",
              defaultMessage: "Workspace Updates",
            })}
            onClick={() => navigateToPage("entitySelect", undefined)}
            icon={<WorkspaceUpdatesIcon />}
          /> */}
            <Menu
              className="w-[180px]"
              placement="bottom-end"
              renderTrigger={({ Trigger, triggerRef }) => (
                <Trigger ref={triggerRef} as={InteractiveContainer}>
                  <IconButton
                    tooltipText={t({
                      id: "main-navbar-component.get-help-tooltip",
                      defaultMessage: "Get Help",
                    })}
                    icon={<HelpIcon />}
                  />
                </Trigger>
              )}
              sections={[
                [
                  {
                    id: "help-center",
                    label: t({
                      id: "main-navbar-component.help-center",
                      defaultMessage: "Help Center",
                    }),
                    onSelect: (_, close) => {
                      window.open("https://help.jugl.com", "_blank");
                      close();
                    },
                    icon: <HelpCenterIcon />,
                  },

                  {
                    id: "support-chat",
                    label: t({
                      id: "main-navbar-component.support-chat",
                      defaultMessage: "Support Chat",
                    }),
                    icon: <SupportChatIcon />,
                    onSelect: (_, close) => {
                      toggleIntercomWidget();
                      close();
                    },
                  },
                ],
              ]}
            />

            <IconButton
              tooltipText={t({
                id: "common.settings",
                defaultMessage: "Settings",
              })}
              onClick={() => navigateToPage("settingsSettings", undefined)}
              icon={<SettingsIcon />}
              isActive={locationRoute === "settings" && !locationTab}
            />

            <IconButton
              tooltipText={t({
                id: "main-navbar-component.my-account",
                defaultMessage: "My Account",
              })}
              onClick={() => navigateToPage("settingsMainSettings")}
              icon={
                <span className="relative">
                  <Avatar
                    className="border-[1px] border-solid border-white"
                    size="sm"
                    imageUrl={me?.profile?.img}
                    username={me?.display_name || ""}
                  />
                  <span className="bg-gradients-success absolute bottom-[2px] right-[2px] box-border h-2 w-2 rounded-full border border-solid border-white" />
                </span>
              }
              isActive={
                locationRoute === "settings" &&
                (locationTab === "main-settings" ||
                  locationTab === "edit-profile")
              }
            />
          </span>
        </div>
      </div>
    </EntitiesListProvider>
  );
};
