import {
  UnreadIndicatorSupportedModule,
  useRestApiProvider,
} from "@jugl-web/rest-api";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useEffect } from "react";

interface UseMarkModuleAsReadOptions {
  entityId: string;
  module: UnreadIndicatorSupportedModule;
  skip?: boolean;
}

export const useMarkModuleAsRead = ({
  entityId,
  module,
  skip,
}: UseMarkModuleAsReadOptions) => {
  const { entitiesApi } = useRestApiProvider();

  const { data: moduleUnreadIndicators } =
    entitiesApi.endpoints.getModuleUnreadIndicators.useQueryState(
      entityId ? { entityId } : skipToken
    );

  const [updateModuleUnreadIndicators] =
    entitiesApi.useUpdateModuleUnreadIndicatorsMutation();

  const [markEntityModuleAsRead] =
    entitiesApi.useMarkEntityModuleAsReadMutation();

  useEffect(() => {
    if (!entityId || !moduleUnreadIndicators || skip) {
      return;
    }

    if (moduleUnreadIndicators[module] === 0) {
      return;
    }

    // Resets the counter in advance to avoid the indicator's flashing effect in the sidebar
    updateModuleUnreadIndicators({
      entityId,
      state: { ...moduleUnreadIndicators, [module]: 0 },
    });

    markEntityModuleAsRead({ entityId, module });
  }, [
    entityId,
    module,
    moduleUnreadIndicators,
    skip,
    markEntityModuleAsRead,
    updateModuleUnreadIndicators,
  ]);
};
