import { getCustomerDetails } from "@jugl-web/domain-resources/customers/utils";
import {
  downloadBlobAsFile,
  useDownloadFile,
} from "@jugl-web/domain-resources/drive";
import { useFilePreview } from "@jugl-web/domain-resources/files/providers/FilePreviewProvider";
import { useSubscriptionInfo } from "@jugl-web/domain-resources/subscription/hooks/useSubscriptionInfo";
import {
  MY_TASKS_SOURCE,
  TaskCompleteConfirmationDialog,
  shortenTaskTitle,
} from "@jugl-web/domain-resources/tasks";
import { TaskCommentsProvider } from "@jugl-web/domain-resources/tasks-comments";
import { CompleteTaskSuggestionDialog } from "@jugl-web/domain-resources/tasks/components/CompleteTaskSuggestionDialog";
import { ManageTaskFieldsDialog } from "@jugl-web/domain-resources/tasks/components/ManageTaskFieldsDialog";
import { ManageTaskFieldsDialogScreenToParametersMap } from "@jugl-web/domain-resources/tasks/components/ManageTaskFieldsDialog/types";
import {
  TaskAttachments,
  TaskAttachmentsHandle,
} from "@jugl-web/domain-resources/tasks/components/TaskAttachments";
import {
  TaskChecklist,
  TaskChecklistHandle,
  taskChecklistItemAdapters,
} from "@jugl-web/domain-resources/tasks/components/TaskChecklist";
import { TaskCompletionDialog } from "@jugl-web/domain-resources/tasks/components/TaskCompletionDialog";
import { TaskCreator } from "@jugl-web/domain-resources/tasks/components/TaskCreator";
import { TitleBox } from "@jugl-web/domain-resources/tasks/components/TitleBox";
import { useCompleteTaskSuggestionDialog } from "@jugl-web/domain-resources/tasks/hooks/useCompleteTaskSuggestionDialog";
import { useMarkTaskAsViewed } from "@jugl-web/domain-resources/tasks/hooks/useMarkTaskAsViewed";
import { useRemoteTaskChecklistHandlers } from "@jugl-web/domain-resources/tasks/hooks/useRemoteTaskChecklistHandlers";
import { useTaskActivities } from "@jugl-web/domain-resources/tasks/hooks/useTaskActivities";
import { useTaskPermissions } from "@jugl-web/domain-resources/tasks/hooks/useTaskPermissions";
import { useTaskPreferences } from "@jugl-web/domain-resources/tasks/hooks/useTaskPreferences";
import { useTaskUnreadIndicator } from "@jugl-web/domain-resources/tasks/hooks/useTaskUnreadIndicator";
import { useUpdateTask } from "@jugl-web/domain-resources/tasks/hooks/useUpdateTask";
import { EntityParticipantRole, useRestApiProvider } from "@jugl-web/rest-api";
import { SubscriptionPlanModuleId } from "@jugl-web/rest-api/entities/models/common-types/SubscriptionPlanModuleId";
import {
  DetailedTask,
  TaskAttachment,
  TaskDefaultStatus,
} from "@jugl-web/rest-api/tasks";
import {
  ConfirmationPopup,
  DRAWER_TRANSITION_DURATION_MS,
  FeedbackBox,
  InteractiveContainer,
} from "@jugl-web/ui-components/cross-platform";
import { FormControlLabel } from "@jugl-web/ui-components/cross-platform/FormControlLabel";
import { LoadingSpinner } from "@jugl-web/ui-components/cross-platform/LoadingSpinner";
import { Menu } from "@jugl-web/ui-components/cross-platform/Menu";
import { Pill } from "@jugl-web/ui-components/cross-platform/Pill";
import { PlainButton } from "@jugl-web/ui-components/cross-platform/PlainButton";
import { Tooltip } from "@jugl-web/ui-components/cross-platform/Tooltip";
import {
  HEADER_BREADCRUMBS_HEIGHT_PX,
  HeaderBreadcrumbs,
  HeaderBreadcrumbsItem,
} from "@jugl-web/ui-components/web/HeaderBreadcrumbs";
import { assert, cx, useToast, useTranslations } from "@jugl-web/utils";
import { Screen } from "@jugl-web/utils/utils/ScreenTransitionManager";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { ManageFieldsTopBarButton } from "@web-src/components/ManageFieldsTopBarButton";
import { TaskHoverableButton } from "@web-src/components/TaskHoverableButton";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { selectUserId } from "@web-src/features/auth/authSlice";
import { ArchiveTaskDialog } from "@web-src/features/tasks/TaskTable/components/TaskMenu/ArchiveTaskDialog";
import { useMarkModuleAsRead } from "@web-src/hooks/useMarkModuleAsRead";
import { AddNewCustomerDialog } from "@web-src/modules/customers/components/AddNewCustomerDialog";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Section } from "./Section";
import { TaskActivitiesColumn } from "./TaskActivitiesColumn";
import { TaskDetailsPropertiesPanel } from "./components/TaskDetailsPropertiesPanel";
import { ReactComponent as ArchiveIcon } from "./icons/archive.svg";
import { ReactComponent as BinIcon } from "./icons/bin.svg";
import { ReactComponent as CopyIcon } from "./icons/copy.svg";
import { ReactComponent as CustomerUpdateIcon } from "./icons/customer-update.svg";
import { ReactComponent as EyeCheckIcon } from "./icons/eye-check.svg";
import { ReactComponent as HorizDotsIcon } from "./icons/horiz-dots.svg";
import { ReactComponent as PlusIcon } from "./icons/plus.svg";
import { ReactComponent as RecurringIcon } from "./icons/recurring.svg";

enum ActivityType {
  activity = "activity",
  feedback = "feedback",
}

const GAP_BETWEEN_DRAWER_OPENINGS_MS = DRAWER_TRANSITION_DURATION_MS + 50;
const MAX_TASK_NAME_LENGTH = 80;

const TaskDetails = () => {
  const { entityId, entity } = useEntitySelectedProvider();
  const { generateUrl } = useNavigation();
  const { taskId, customerId } = useParams<{
    taskId: string;
    customerId: string;
  }>();
  const [selectedActivityTabType, setSelectedActivityTabType] =
    useState<ActivityType>(ActivityType.activity);

  const { t } = useTranslations();

  const meId = useSelector(selectUserId);
  const me = useMe();
  const hasRequiredParams = !!taskId && !!meId;
  const isAdmin = entity.role === EntityParticipantRole.admin;

  const { tasksApi, customersApi } = useRestApiProvider();

  const {
    data: task,
    isLoading,
    isError: isGetTaskError,
    error,
  } = tasksApi.useGetTaskQuery(
    hasRequiredParams ? { taskId, entityId } : skipToken
  );

  const { isModuleAvailable } = useSubscriptionInfo({ entityId });

  const { data: customer } = customersApi.useCustomerQuery(
    customerId
      ? {
          entityId,
          customerId,
        }
      : skipToken
  );

  const [sendUpdateToCustomer] = tasksApi.useSendUpdateToCustomerMutation();

  const isError = isGetTaskError || task?._is_deleted;
  const isNotFoundError =
    task?._is_deleted || (error && "data" in error && error.status === 404);

  const permissions = useTaskPermissions({ task, meId });
  const {
    activities,
    isLoading: areActivitiesLoading,
    loadMore: loadMoreActivities,
    activitiesFilters,
    isInitialLoading,
    addActivity,
    modifyActivity,
    setActivitiesFilters,
  } = useTaskActivities({
    meUser: me.me,
    entityId,
    taskId,
  });

  const isSendUpdateToCustomerOptionVisible = useMemo(
    () =>
      isModuleAvailable(SubscriptionPlanModuleId.clients) &&
      (!!task?.cust_id || permissions.canEditCustomer),
    [task, permissions, isModuleAvailable]
  );

  const { isTaskUnread, readTask } = useTaskUnreadIndicator({
    entityId,
    source: MY_TASKS_SOURCE,
  });

  const updateTask = useUpdateTask({ entityId, taskId: taskId as string });
  const [deleteTask] = tasksApi.useDeleteTaskMutation();

  const taskChecklistHandlers = useRemoteTaskChecklistHandlers({
    entityId,
    taskId: taskId as string,
    onProducedActivity: addActivity,
  });

  const { toast, closeToast } = useToast({ variant: "web" });
  const { navigateToPage, navigateBack } = useNavigation();

  const updateTaskAndAddActivity = useCallback(
    async (attributes: Partial<DetailedTask>) => {
      assert(!!taskId, "Task ID is required at this point");

      try {
        const response = await updateTask(attributes);

        addActivity(response.activity);

        if (!response.task.cust_id) {
          setSelectedActivityTabType(ActivityType.activity);
          setActivitiesFilters([]);
        }
      } catch {
        // Do nothing
      }
    },
    [addActivity, setActivitiesFilters, taskId, updateTask]
  );

  const $taskChecklistRef = useRef<TaskChecklistHandle | null>(null);

  const { dialogProps: completeTaskSuggestionDialogProps } =
    useCompleteTaskSuggestionDialog({
      task,
      meId: meId || "",
      onComplete: () => {
        updateTaskAndAddActivity({ status: TaskDefaultStatus.completed });
        toast(
          t({
            id: "tasks-page.task-completed",
            defaultMessage: "Task has been completed",
          })
        );
      },
    });

  const [
    isCompleteConfirmationDialogOpen,
    setIsCompleteConfirmationDialogOpen,
  ] = useState(false);

  const [manageFieldsDialogScreen, setManageFieldsDialogScreen] =
    useState<Screen<ManageTaskFieldsDialogScreenToParametersMap> | null>(null);
  const isManageFieldsDialogOpen = !!manageFieldsDialogScreen;

  const initialManageFieldsDialogScreen = useMemo(
    () => manageFieldsDialogScreen || ({ name: "entry" } as const),
    [manageFieldsDialogScreen]
  );

  const [isAddNewCustomerDialogOpen, setIsAddNewCustomerDialogOpen] =
    useState(false);

  const [isArchiveTaskDialogOpen, setIsArchiveTaskDialogOpen] = useState(false);

  const [isDeletionDialogOpen, setIsDeletionDialogOpen] = useState(false);

  const [isTaskCompletionDialogOpen, setIsTaskCompletionDialogOpen] =
    useState(false);
  const taskCompletionDialogTimer = useRef<number>();

  const handleCopyTaskLink = () => {
    assert(!!task, "Task is not loaded");

    navigator.clipboard.writeText(task.short_url).then(() => {
      toast(
        t({
          id: "feedback.task-link-copied",
          defaultMessage: "Task link has been copied to clipboard",
        })
      );
    });
  };

  const handleOpenTaskCompletionDialogWithDelay = () => {
    taskCompletionDialogTimer.current = window.setTimeout(
      () => setIsTaskCompletionDialogOpen(true),
      GAP_BETWEEN_DRAWER_OPENINGS_MS
    );
  };

  const activityTabs = useMemo(
    () => [
      {
        type: ActivityType.activity,
        label: t({
          id: "tasks-page.activity",
          defaultMessage: "Activity",
        }),
      },
      {
        type: ActivityType.feedback,
        label: t({
          id: "tasks-page.client-feedback",
          defaultMessage: "Client Feedback",
        }),
        isHidden: !task?.cust_id,
      },
    ],
    [t, task?.cust_id]
  );

  const breadcrumbsItems = useMemo<HeaderBreadcrumbsItem[]>(() => {
    const navigation = [];

    if (customerId) {
      navigation.push({
        title: t({
          id: "tasks-page.customers",
          defaultMessage: "Customers",
        }),
        href: generateUrl("customersCustomers"),
      });

      if (customer) {
        navigation.push({
          title: getCustomerDetails(customer.data).fullName,
          href: generateUrl("customersTasks", { customerId }),
        });
      }
    } else {
      navigation.push({
        title: t({
          id: "tasks-page.tasks",
          defaultMessage: "Tasks",
        }),
        href: generateUrl("tasksTasks", { boardId: "my" }),
      });
    }

    if (task && !task._is_deleted) {
      navigation.push({
        title: shortenTaskTitle(task.name || "", MAX_TASK_NAME_LENGTH),
        isHidden: isLoading,
      });
    } else {
      navigation.push({
        title: t({
          id: "tasks-page.task-details",
          defaultMessage: "Task details",
        }),
        isHidden: isLoading,
      });
    }

    return navigation;
  }, [isLoading, task, t, customerId, generateUrl, customer]);

  const handleRemoveTask = async () => {
    assert(hasRequiredParams);

    deleteTask({ entityId, taskId }).then((response) => {
      if ("data" in response) {
        if (customerId) {
          navigateToPage("customersTasks", { customerId });
        } else {
          navigateToPage("tasksTasks", { boardId: "my" });
        }
        toast(
          t({
            id: "feedback.task-deleted",
            defaultMessage: "Task has been deleted",
          })
        );
        setIsDeletionDialogOpen(false);
      }
    });
  };

  const handleSendUpdateToCustomer = useCallback(async () => {
    assert(!!task);
    const response = await sendUpdateToCustomer({
      entityId,
      taskId: task.id,
    });
    if ("data" in response) {
      toast(
        t({
          id: "tasks-page.update-sent-to-customer",
          defaultMessage: "Update was shared with Customer",
        })
      );
    }
  }, [task, sendUpdateToCustomer, entityId, toast, t]);

  const handleSendUpdateToCustomerFlow = async () => {
    assert(!!task);
    if (task.cust_id) {
      handleSendUpdateToCustomer();
    }
    if (!task.cust_id) {
      const customerFieldElement: HTMLButtonElement | null =
        document.querySelector("[data-id='customer-field']");
      if (customerFieldElement) {
        customerFieldElement.click();
      }
      toast(
        t({
          id: "tasks-page.assign-customer-to-send-update",
          defaultMessage: "To send update, assign Customer to the Task first",
        }),
        {
          variant: "error",
        }
      );
    }
  };

  useEffect(
    () => () => {
      clearTimeout(taskCompletionDialogTimer.current);
    },
    []
  );

  useEffect(() => {
    if (task && isTaskUnread(task.id)) {
      readTask(task.id);
    }
  }, [isTaskUnread, readTask, task]);

  useMarkTaskAsViewed({ entityId, taskId: task?.id });
  useMarkModuleAsRead({ entityId, module: "task" });

  const adaptedChecklistItems = useMemo(
    () =>
      (task?.checklist || []).map(taskChecklistItemAdapters.toInternalModel),
    [task?.checklist]
  );

  const { preferences, updatePreference } = useTaskPreferences({
    taskId: task?.id,
  });

  const $taskAttachmentsRef = useRef<TaskAttachmentsHandle | null>(null);
  const $attachmentsSectionRef = useRef<HTMLDivElement | null>(null);

  const { previewFile } = useFilePreview();

  const { downloadFile } = useDownloadFile();

  const handleDownloadAttachment = async (attachment: TaskAttachment) => {
    assert(!!task);

    const downloadingToastId = toast(
      t({ id: "feedback.file-downloading", defaultMessage: "Downloading..." }),
      { variant: "info", persist: true }
    );

    const response = await downloadFile({
      entityId,
      id: attachment.id,
      fileName: attachment.name,
      fileSize: attachment.size,
      mimeType: attachment.content_type,
    });

    if ("data" in response) {
      downloadBlobAsFile(response.data, attachment.name);
    } else {
      toast(
        t(
          {
            id: "tasks-page.attachment-download-failed",
            defaultMessage: "Couldn't download file {fileName}",
          },
          { fileName: attachment.name }
        ),
        { variant: "error", style: { zIndex: 99999 } }
      );
    }

    closeToast(downloadingToastId);
  };

  const handlePreviewAttachment = (attachment: TaskAttachment) => {
    previewFile({
      url: attachment.stream_url,
      name: attachment.name,
      mimeType: attachment.content_type,
      onDownload: () => handleDownloadAttachment(attachment),
    });
  };

  return (
    <div className="jugl__border-box-component bg-grey-200 flex min-h-full flex-col">
      <HeaderBreadcrumbs
        items={breadcrumbsItems}
        rightContent={
          <>
            {isAdmin && (
              <ManageFieldsTopBarButton
                onClick={() => setManageFieldsDialogScreen({ name: "entry" })}
              />
            )}
          </>
        }
      />
      <div className="flex grow overflow-x-auto">
        {task && hasRequiredParams && !task._is_deleted ? (
          <div className="animate-fade-in flex grow px-8">
            <div
              className="flex grow flex-col overflow-y-auto"
              style={{
                maxHeight: `calc(100vh - ${HEADER_BREADCRUMBS_HEIGHT_PX}px)`,
              }}
            >
              <Section className="rounded-b-2xl border-b-0 pt-10">
                <div className="relative mb-2.5">
                  <TaskCreator
                    entityId={entityId}
                    userId={task.created_by}
                    createdAt={task.created_at}
                  />
                  <div className="absolute top-0 right-0 flex items-center gap-4">
                    <Tooltip
                      className="w-[110px] text-center"
                      renderTrigger={({ ref, props }) => (
                        <TaskHoverableButton
                          ref={ref}
                          onClick={handleCopyTaskLink}
                          {...props}
                        >
                          <CopyIcon />
                        </TaskHoverableButton>
                      )}
                    >
                      {t({
                        id: "tasks-page.copy-task-link",
                        defaultMessage: "Copy task link",
                      })}
                    </Tooltip>
                    <Menu
                      sections={[
                        [
                          {
                            id: "hide-completed-checklist-items",
                            label: t({
                              id: "tasks-page.hide-completed-checklists",
                              defaultMessage: "Hide completed checklists",
                            }),
                            icon: <EyeCheckIcon />,
                            toggle: "checkbox",
                            isSelected:
                              preferences.areCompletedChecklistItemsHidden,
                            onSelect: () =>
                              updatePreference(
                                "areCompletedChecklistItemsHidden",
                                (previousState) => !previousState
                              ),
                          },
                          {
                            id: "send-update-to-customer",
                            label: t({
                              id: "tasks-page.send-update-to-the-customer",
                              defaultMessage: "Send update to the Customer",
                            }),
                            icon: <CustomerUpdateIcon />,
                            isHidden:
                              !isSendUpdateToCustomerOptionVisible ||
                              !permissions.canSendUpdateToCustomer ||
                              task.is_self,
                            onSelect: (_, close) => {
                              handleSendUpdateToCustomerFlow();
                              close();
                            },
                          },
                          {
                            id: "archive-task",
                            label: t({
                              id: "tasks-page.archive-task",
                              defaultMessage: "Archive Task",
                            }),
                            icon: <ArchiveIcon />,
                            isHidden: !permissions.canArchiveTask,
                            onSelect: (_, close) => {
                              close();
                              setIsArchiveTaskDialogOpen(true);
                            },
                          },
                          {
                            id: "delete-task",
                            label: t({
                              id: "tasks-page.delete-task",
                              defaultMessage: "Delete task",
                            }),
                            icon: <BinIcon />,
                            isHidden: !permissions.canDeleteTask,
                            onSelect: (_, close) => {
                              close();
                              setIsDeletionDialogOpen(true);
                            },
                          },
                        ],
                      ]}
                      className="py-2"
                      placement="bottom-end"
                      renderTrigger={({ Trigger, triggerRef }) => (
                        <Trigger as={TaskHoverableButton} ref={triggerRef}>
                          <HorizDotsIcon />
                        </Trigger>
                      )}
                    />
                  </div>
                </div>
                <div className="flex items-start justify-between gap-4">
                  <div className="mt-[5.5px] flex w-full flex-col">
                    <div className="flex w-4/5 max-w-[700px] items-center gap-3">
                      {task.recurrence && (
                        <Tooltip
                          placement="bottom"
                          renderTrigger={({ ref, props }) => (
                            <div
                              ref={ref}
                              className="bg-tertiary-100 flex h-8 w-8 items-center justify-center rounded-md"
                              {...props}
                            >
                              <RecurringIcon />
                            </div>
                          )}
                        >
                          {t({
                            id: "tasks-page.recurring-task",
                            defaultMessage: "Recurring task",
                          })}
                        </Tooltip>
                      )}
                      <div className="grow">
                        <TitleBox
                          prefix={task.order_id || undefined}
                          isOrder={!!task.order_id}
                          title={task.name || ""}
                          isEditable={permissions.canEditTitle}
                          onChange={(title) =>
                            updateTaskAndAddActivity({ name: title })
                          }
                          classes={{
                            titleText: "py-3",
                          }}
                        />
                      </div>
                    </div>
                    <TaskDetailsPropertiesPanel
                      entityId={entityId}
                      updateCurrentTask={updateTaskAndAddActivity}
                      task={task}
                      permissions={permissions}
                      onAttachmentsClick={() => {
                        $attachmentsSectionRef.current?.scrollIntoView({
                          behavior: "smooth",
                        });
                      }}
                      onAddNewCustomer={() => {
                        setIsAddNewCustomerDialogOpen(true);
                      }}
                    />
                  </div>
                  <AddNewCustomerDialog
                    isOpen={isAddNewCustomerDialogOpen}
                    onClose={() => {
                      setIsAddNewCustomerDialogOpen(false);
                    }}
                    onSuccess={({ id }) =>
                      updateTaskAndAddActivity({ cust_id: id })
                    }
                  />
                  <TaskCompleteConfirmationDialog
                    isOpen={isCompleteConfirmationDialogOpen}
                    variant="web"
                    entityId={entityId}
                    task={task}
                    onClose={() => {
                      setIsCompleteConfirmationDialogOpen(false);
                    }}
                    onCompletion={handleOpenTaskCompletionDialogWithDelay}
                  />
                  <CompleteTaskSuggestionDialog
                    {...completeTaskSuggestionDialogProps}
                  />
                  <TaskCompletionDialog
                    isOpen={isTaskCompletionDialogOpen}
                    onClose={() => setIsTaskCompletionDialogOpen(false)}
                    taskTitle={task.name || ""}
                  />
                  <ConfirmationPopup
                    variant="web"
                    isOpen={isDeletionDialogOpen}
                    title={t({
                      id: "tasks-page.delete-task",
                      defaultMessage: "Delete task",
                    })}
                    message={t({
                      id: "tasks-page.confirm-delete-task",
                      defaultMessage:
                        "Are you sure you want to delete this task?",
                    })}
                    hasCancelButton
                    buttons={[
                      {
                        color: "tertiary",
                        label: t({
                          id: "common.delete",
                          defaultMessage: "Delete",
                        }),
                        onClick: handleRemoveTask,
                      },
                    ]}
                    onRequestClose={() => setIsDeletionDialogOpen(false)}
                  />
                </div>
              </Section>
              <Section className="mt-3 flex flex-col gap-4 rounded-t-2xl">
                <div className="flex items-center justify-between">
                  <FormControlLabel>
                    {t({
                      id: "tasks-page.checklist",
                      defaultMessage: "Checklist",
                    })}
                  </FormControlLabel>
                  {permissions.canManageChecklistItem && (
                    <PlainButton
                      onClick={() => $taskChecklistRef.current?.addItem()}
                    >
                      <Pill
                        size="md"
                        endIcon={<PlusIcon />}
                        label={t({
                          id: "common.add",
                          defaultMessage: "Add",
                        })}
                      />
                    </PlainButton>
                  )}
                </div>
                <TaskChecklist
                  ref={$taskChecklistRef}
                  entityId={entityId}
                  meId={meId}
                  items={adaptedChecklistItems}
                  taskCreatorId={task.created_by}
                  taskAssigneeIds={task.assignees}
                  isCompleteInSpecificOrder={task.has_chklist_chk}
                  areCompletedItemsHidden={
                    preferences.areCompletedChecklistItemsHidden
                  }
                  isEditable={permissions.canManageChecklistItem}
                  isCompletable={permissions.canCompleteChecklistItem}
                  isAssignable={!task.is_self}
                  className="mt-4"
                  {...taskChecklistHandlers}
                />
              </Section>
              <Section className="px-0">
                <div
                  className="flex items-center justify-between px-8"
                  ref={$attachmentsSectionRef}
                >
                  <FormControlLabel>
                    {t({
                      id: "tasks-page.attachments",
                      defaultMessage: "Attachments",
                    })}
                  </FormControlLabel>
                  {permissions.canManageAttachments && (
                    <PlainButton
                      onClick={() =>
                        $taskAttachmentsRef.current?.openFilePicker()
                      }
                    >
                      <Pill
                        size="md"
                        endIcon={<PlusIcon />}
                        label={t({
                          id: "common.add",
                          defaultMessage: "Add",
                        })}
                      />
                    </PlainButton>
                  )}
                </div>
                <div className="px-8 pt-4">
                  <TaskAttachments
                    mode="uncontrolled"
                    ref={$taskAttachmentsRef}
                    attachments={task.attachments}
                    entityId={entityId}
                    taskId={task.id}
                    isEditable={permissions.canManageAttachments}
                    onPreviewAttachment={handlePreviewAttachment}
                  />
                </div>
              </Section>
            </div>
          </div>
        ) : isError ? (
          <div className="mx-auto flex items-center">
            {isNotFoundError ? (
              <FeedbackBox
                iconType="network-error"
                title={t({
                  id: "tasks-page.task-not-found",
                  defaultMessage: "Task not found",
                })}
                subtitle={t({
                  id: "tasks-page.task-access-error",
                  defaultMessage:
                    "It may have been deleted or you don't have access to it",
                })}
                actionButton={{
                  label: t({
                    id: "common.go-back",
                    defaultMessage: "Go back",
                  }),
                  onClick: navigateBack,
                }}
              />
            ) : (
              <FeedbackBox
                iconType="network-error"
                title={t({
                  id: "feedback.network-error",
                  defaultMessage: "Network error",
                })}
                subtitle={t(
                  {
                    id: "feedback.inconvenience-occurred-try-again-later",
                    defaultMessage:
                      "We're sorry for the inconvenience.{br}Please try again later",
                  },
                  {
                    br: <br />,
                  }
                )}
                actionButton={{
                  label: t({
                    id: "common.refresh-page",
                    defaultMessage: "Refresh page",
                  }),
                  onClick: () => window.location.reload(),
                }}
              />
            )}
          </div>
        ) : (
          <div className="mx-auto mt-8">
            <LoadingSpinner />
          </div>
        )}
        {!!meId && !!entityId && !!taskId && !!task && !isNotFoundError && (
          <div
            className="flex w-[480px] min-w-[480px] flex-col overflow-y-auto bg-white"
            style={{
              boxShadow: "-2px 0px 3px rgba(0, 0, 0, 0.05)",
              maxHeight: `calc(100vh - 64px)`,
            }}
          >
            <div className="my-4 flex">
              {task.cust_id &&
                activityTabs.map((tab) => (
                  <InteractiveContainer
                    className={cx("w-full pt-2 text-center", {
                      hidden: tab.isHidden,
                    })}
                    key={tab.type}
                    onClick={() => {
                      setSelectedActivityTabType(tab.type);
                      setActivitiesFilters(
                        tab.type === ActivityType.feedback ? ["guest"] : []
                      );
                    }}
                  >
                    <span
                      className={cx(
                        "leading-[160%] transition-colors",
                        selectedActivityTabType === tab.type
                          ? "text-primary-800 font-medium"
                          : "text-[#69757C]"
                      )}
                    >
                      {tab.label}
                    </span>
                    <div
                      className={cx(
                        "mt-3 h-1 rounded-lg transition-colors",
                        selectedActivityTabType === tab.type
                          ? "bg-primary"
                          : "bg-grey-300"
                      )}
                    />
                  </InteractiveContainer>
                ))}
            </div>
            <TaskCommentsProvider
              userId={meId}
              entityId={entityId}
              taskId={taskId}
              isEditable={permissions.canManageComments}
              task={task}
            >
              <TaskActivitiesColumn
                entityId={entityId}
                taskId={taskId}
                activities={activities}
                loadMoreActivities={loadMoreActivities}
                areActivitiesLoading={areActivitiesLoading}
                addActivity={addActivity}
                modifyActivity={modifyActivity}
                activitiesFilters={activitiesFilters}
                setActivitiesFilters={setActivitiesFilters}
                isInitialLoading={isInitialLoading}
                hasCustomerAssigned={!!task.cust_id}
              />
            </TaskCommentsProvider>
          </div>
        )}
      </div>

      <ArchiveTaskDialog
        isOpen={isArchiveTaskDialogOpen}
        onClose={() => setIsArchiveTaskDialogOpen(false)}
        taskName={task?.name || ""}
        taskId={task?.id || ""}
        onSuccess={() => {
          navigateToPage("tasksTasks", { boardId: "my" });
        }}
      />

      {hasRequiredParams && (
        <ManageTaskFieldsDialog
          isOpen={isManageFieldsDialogOpen}
          entityId={entityId}
          initialScreen={initialManageFieldsDialogScreen}
          onClose={() => setManageFieldsDialogScreen(null)}
        />
      )}
    </div>
  );
};

export default TaskDetails;
