import { autoUpdate, offset, useFloating } from "@floating-ui/react-dom";
import {
  InteractiveContainer,
  MultiSectionLayout,
} from "@jugl-web/ui-components/cross-platform";
import { cx, useTranslations } from "@jugl-web/utils";
import { FC, useCallback, useState } from "react";
import { useEntityProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { Link } from "react-router-dom";
import { TaskBoardDialog } from "@web-src/features/tasks/TaskBoardDialog";
import { Tooltip } from "@jugl-web/ui-components";
// import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import useEntity from "../../hooks/useEntity";
import { Divider } from "./components/Divider";
import { EntitiesUpdatesPopup } from "./components/EntitiesUpdatesPopup";
import { HomeSideBarAccordion } from "./components/HomeSideBarAccordion";
import { UnreadCountBox } from "./components/UnreadCountBox";
import styles from "./HomeSideBar.module.css";
import { ReactComponent as ChevronRightIcon } from "./icons/chevron-right.svg";
import { NavigationItem } from "./types";
import { useEntitiesUpdatesPopup } from "./useEntitiesUpdatesPopup";
import { useHomeSideBar } from "./useHomeSideBar";
import { EntitySubscriptionInfoItem } from "./components/EntitySubscriptionInfoItem/EntitySubscriptionInfoItem";
import { ReactComponent as CreateBoardIcon } from "./icons/create-board.svg";
import { SidebarLinkButton } from "./components/SidebarLinkButton";
// import { useMe } from "../../hooks/useMe";

export const HomeSideBar: FC = () => {
  const { entity, list } = useEntity();
  const { isModuleAvailable, moduleRequiredAction } = useEntityProvider();
  const { t } = useTranslations();
  // const { profile } = useMe();
  // const { navigateToPage } = useNavigation();
  const [isBoardDialogOpen, setBoardDialogOpen] = useState(false);
  const {
    navigationItems,
    tasksNavigationItems,
    isSideBarExpanded,
    updateIsSideBarExpanded,
  } = useHomeSideBar();

  const {
    isEntitiesUpdatesPopupOpen,
    lastUpdatedEntityId,
    onCloseEntitiesUpdatesPopup,
  } = useEntitiesUpdatesPopup({ entityId: entity?.id });

  const { refs, floatingStyles } = useFloating({
    placement: "right-end",
    middleware: [offset({ mainAxis: isSideBarExpanded ? -15 : -10 })],
    whileElementsMounted: autoUpdate,
    transform: false,
  });

  const renderSideBarElements = useCallback(
    (elements: NavigationItem[]) =>
      elements
        .filter(
          (navigationItem) =>
            !navigationItem.hidden &&
            (navigationItem.requiredRole
              ? entity && navigationItem.requiredRole.includes(entity.role)
              : true)
        )
        .map((navigationItem) => {
          if (navigationItem.type === "accordion") {
            return (
              <Tooltip
                isDisabled={isSideBarExpanded}
                placement="right"
                renderTrigger={({ props, ref }) => (
                  <span {...props} ref={ref}>
                    <HomeSideBarAccordion
                      {...props}
                      key={navigationItem.title}
                      title={
                        <div className="flex flex-col overflow-hidden">
                          <div
                            className={cx(
                              "flex items-center gap-3",
                              isSideBarExpanded ? "px-2.5" : "justify-center"
                            )}
                          >
                            {navigationItem.icon && (
                              <navigationItem.icon
                                className={cx(
                                  "mb-[2px] shrink-0 transition-transform duration-300"
                                )}
                              />
                            )}
                            <span
                              className={cx(
                                "font-secondary truncate text-sm",
                                isSideBarExpanded ? "block" : "hidden"
                              )}
                            >
                              {navigationItem.title}
                            </span>
                          </div>
                        </div>
                      }
                      isHomeSidebarExpanded={isSideBarExpanded}
                      onSideBarExpand={() => updateIsSideBarExpanded(true)}
                      onClick={navigationItem.onClick}
                    >
                      {renderSideBarElements(navigationItem.items)}
                    </HomeSideBarAccordion>
                  </span>
                )}
              >
                {navigationItem.title}
              </Tooltip>
            );
          }

          const navigationItemContent = (
            <>
              <div
                className={cx(
                  "flex items-center",
                  navigationItem.icon ? "gap-2" : "gap-7",
                  isSideBarExpanded ? "px-2.5" : "justify-center"
                )}
              >
                <div className="relative mb-[2px] flex h-full items-center leading-[0]">
                  {navigationItem.icon && (
                    <navigationItem.icon
                      className={cx("transition-transform duration-300")}
                    />
                  )}
                  {!isSideBarExpanded &&
                    (navigationItem.unreadCount || 0) > 0 && (
                      <UnreadCountBox
                        count={navigationItem.unreadCount as number}
                        isItemActive={!!navigationItem.active}
                        className="absolute right-[6px] top-1/2 -translate-y-1/2 translate-x-full"
                      />
                    )}
                </div>
                <div
                  className={cx(
                    isSideBarExpanded
                      ? "flex w-full items-center justify-between truncate"
                      : "hidden"
                  )}
                >
                  <span className="font-secondary truncate text-sm leading-[21px]">
                    {navigationItem.title}
                  </span>
                  {(navigationItem.unreadCount || 0) > 0 && (
                    <UnreadCountBox
                      count={navigationItem.unreadCount as number}
                      isItemActive={!!navigationItem.active}
                    />
                  )}
                </div>
              </div>
            </>
          );

          const navigationItemStyles = cx(
            "group mx-2.5 flex shrink-0 select-none flex-col justify-center rounded-lg text-white no-underline transition-colors duration-300 h-[41px] w-[60px]",
            isSideBarExpanded ? "w-[190px]" : "w-[60px]",
            navigationItem.active
              ? "bg-primary-800 font-semibold"
              : "hover:bg-[#2F7DDA]"
          );

          return navigationItem.type === "link" ? (
            <Tooltip
              isDisabled={isSideBarExpanded}
              placement="right"
              renderTrigger={({ props, ref }) => (
                <Link
                  {...props}
                  ref={ref}
                  key={navigationItem.title}
                  className={navigationItemStyles}
                  to={navigationItem.href}
                  onClick={(e) => {
                    if (
                      navigationItem.subscriptionModuleRequired &&
                      !isModuleAvailable(
                        navigationItem.subscriptionModuleRequired
                      )
                    ) {
                      e.preventDefault();
                      moduleRequiredAction(
                        navigationItem.subscriptionModuleRequired
                      );
                    }

                    navigationItem.onClick?.();
                  }}
                >
                  {navigationItemContent}
                </Link>
              )}
            >
              {navigationItem.title}
            </Tooltip>
          ) : (
            <InteractiveContainer
              key={navigationItem.title}
              className={navigationItemStyles}
              onClick={() =>
                navigationItem.subscriptionModuleRequired
                  ? moduleRequiredAction(
                      navigationItem.subscriptionModuleRequired,
                      navigationItem.onClick
                    )
                  : navigationItem.onClick?.()
              }
            >
              {navigationItemContent}
            </InteractiveContainer>
          );
        }),
    [
      entity,
      isSideBarExpanded,
      updateIsSideBarExpanded,
      isModuleAvailable,
      moduleRequiredAction,
    ]
  );

  return (
    <>
      <TaskBoardDialog
        isOpen={isBoardDialogOpen}
        onClose={() => setBoardDialogOpen(false)}
      />
      <MultiSectionLayout>
        <div
          className={cx(
            "bg-primary-700 group/homeSideBar flex h-full flex-col overflow-x-hidden transition-[width] duration-300",
            isSideBarExpanded ? "w-[210px]" : "w-[80px]"
          )}
        >
          <div className="relative flex shrink-0 flex-col">
            <InteractiveContainer
              className={cx(
                "bg-primary-600 border-px fixed top-[48px] z-[51] flex h-6 w-6 translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full border-solid border-white text-white opacity-0 transition-all duration-300 group-hover/homeSideBar:opacity-100",
                isSideBarExpanded && "-rotate-180",
                isSideBarExpanded ? "left-[185px]" : "left-[52px]"
              )}
              onClick={() => updateIsSideBarExpanded(!isSideBarExpanded)}
            >
              <ChevronRightIcon />
            </InteractiveContainer>
          </div>
          <div
            className={cx(
              "flex grow flex-col overflow-y-auto overflow-x-hidden pt-3 pb-4",
              isSideBarExpanded ? "items-start gap-2.5" : "items-center gap-1",
              styles.homeSideBarScrollbar
            )}
            ref={refs.setReference}
          >
            {renderSideBarElements(navigationItems)}
            <div className="w-full py-4">
              <Divider />
            </div>

            <SidebarLinkButton
              icon={<CreateBoardIcon />}
              text={t({
                id: "home-sidebar-component.create-board",
                defaultMessage: "Create Board",
              })}
              isSidebarExpanded={isSideBarExpanded}
              tooltip={t({
                id: "home-sidebar-component.create-board",
                defaultMessage: "Create Board",
              })}
              onClick={() => setBoardDialogOpen(true)}
            />
            {tasksNavigationItems.map((navigationItem) => (
              <SidebarLinkButton
                key={navigationItem.title}
                icon={navigationItem.icon}
                text={navigationItem.title}
                isActive={navigationItem.active}
                tooltip={navigationItem.title}
                isSidebarExpanded={isSideBarExpanded}
                onClick={navigationItem.onClick}
              />
            ))}
          </div>
          <EntitySubscriptionInfoItem isSideBarExpanded={isSideBarExpanded} />
        </div>
      </MultiSectionLayout>

      <EntitiesUpdatesPopup
        ref={refs.setFloating}
        style={floatingStyles}
        isOpen={isEntitiesUpdatesPopupOpen}
        entities={list}
        lastUpdatedEntityId={lastUpdatedEntityId}
        onClose={onCloseEntitiesUpdatesPopup}
      />
    </>
  );
};
