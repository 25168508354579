import { EntityParticipantRole } from "@jugl-web/rest-api";
import { SubscriptionPlanModuleId } from "@jugl-web/rest-api/entities/models/common-types/SubscriptionPlanModuleId";
import { useTranslations } from "@jugl-web/utils";
import {
  getStorageItemWithFallback,
  saveItemToStorage,
  SIDEBAR_EXPANDED_KEY,
} from "@jugl-web/utils/storage";
import { useUnreadIndicators } from "@web-src/components/UnreadIndicatorsProvider";
import { useUserAuth } from "@web-src/modules/auth/providers/UserAuthProvider";
import { isAppInDevMode } from "@web-src/modules/common/utils/isAppInDevMode";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useFCM } from "@web-src/modules/notifications/providers/FCMProvider";
import { useCallback, useMemo, useState } from "react";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { useTaskBoards } from "@jugl-web/domain-resources/tasks/hooks/useTaskBoards";
import { TabRoute } from "../../types";
import { ReactComponent as CallsIcon } from "./icons/calls.svg";
import { ReactComponent as ChatsIcon } from "./icons/chats.svg";
import { ReactComponent as ClientsIcon } from "./icons/clients.svg";
import { ReactComponent as DriveIcon } from "./icons/drive.svg";
import { ReactComponent as LogoutIcon } from "./icons/logout.svg";
import { ReactComponent as MoreIcon } from "./icons/more.svg";
import { ReactComponent as OrderFormsIcon } from "./icons/order-forms.svg";
import { ReactComponent as PeopleIcon } from "./icons/people.svg";
import { ReactComponent as ReportsIcon } from "./icons/reports.svg";
import { ReactComponent as ScheduleIcon } from "./icons/schedule.svg";
import { ReactComponent as SettingsIcon } from "./icons/settings.svg";
import { ReactComponent as WorkspaceIcon } from "./icons/workspace.svg";
import { NavigationItem, TaskNavigationItem } from "./types";
import { ProjectIcon } from "./components/ProjectIcon";

export const useHomeSideBar = () => {
  const [isSideBarExpanded, setIsSideBarExpanded] = useState(() =>
    getStorageItemWithFallback(SIDEBAR_EXPANDED_KEY, true)
  );

  const { entity } = useEntitySelectedProvider();
  const { boards } = useTaskBoards({ entityId: entity.id });
  const { t } = useTranslations();
  const { logout } = useUserAuth();
  const { logEvent } = useFCM();
  const { moduleUnreadIndicators } = useUnreadIndicators();
  const { navigateToPage } = useNavigation();

  // TODO: some better approach will be
  const activeTab = window.location.pathname.split("/")[2] || "";
  const activePath = window.location.pathname.split("/").slice(2).join("/");
  const updateIsSideBarExpanded = useCallback((isExpanded: boolean) => {
    setIsSideBarExpanded(isExpanded);
    saveItemToStorage(SIDEBAR_EXPANDED_KEY, isExpanded);
  }, []);

  const navigationItems = useMemo<NavigationItem[]>(() => {
    const chatItem: NavigationItem = {
      type: "link",
      icon: ChatsIcon,
      title: t({
        id: "home-sidebar-component.chats",
        defaultMessage: "Chats",
      }),
      active: activeTab === "chats",
      unreadCount: moduleUnreadIndicators?.chat,
      href: `/${entity.id}/chats`,
      onClick: () => {
        logEvent("navigation_chat");
      },
    };
    const peopleItem: NavigationItem = {
      type: "link",
      icon: PeopleIcon,
      title: t({
        id: "home-sidebar-component.people",
        defaultMessage: "People",
      }),
      active: activeTab === "people",
      unreadCount: moduleUnreadIndicators?.people,
      href: `/${entity?.id}/people`,
    };
    const driveItem: NavigationItem = {
      type: "link",
      icon: DriveIcon,
      title: t({
        id: "home-sidebar-component.drive",
        defaultMessage: "Drive",
      }),
      active: activeTab === "drive",
      unreadCount: moduleUnreadIndicators?.drive,
      href: `/${entity.id}/drive`,
    };
    const callsItem: NavigationItem = {
      type: "link",
      icon: CallsIcon,
      title: t({
        id: "home-sidebar-component.calls",
        defaultMessage: "Calls",
      }),
      active: activeTab === "calls",
      unreadCount: moduleUnreadIndicators?.call,
      href: `/${entity.id}/calls`,
    };

    const moreItem: NavigationItem = {
      type: "accordion",
      icon: MoreIcon,
      title: t({
        id: "home-sidebar-component.more",
        defaultMessage: "More",
      }),
      items: [
        {
          type: "link",
          icon: SettingsIcon,
          title: t({
            id: "home-sidebar-component.settings",
            defaultMessage: "Settings",
          }),
          active: activeTab === "settings",
          href: `/${entity.id}/settings`,
        },
        {
          type: "button",
          icon: LogoutIcon,
          title: t({
            id: "common.log-out",
            defaultMessage: "Log out",
          }),
          onClick: () => logout(true),
        },
      ],
    };

    const devSection: NavigationItem[] = isAppInDevMode()
      ? [
          {
            type: "accordion",
            icon: () => <>⚙️</>,
            title: "Debug",
            items: [
              {
                type: "link",
                title: "User settings",
                active: activePath === "debug/user-settings",
                href: `/${entity.id}/debug/user-settings`,
              },
              {
                type: "link",
                title: "Subscription",
                active: activePath === "debug/subscription",
                href: `/${entity.id}/debug/subscription`,
              },
              {
                type: "link",
                title: "Mobile app",
                active: activePath === "debug/mobile-app",
                href: `/${entity.id}/debug/mobile-app`,
              },
            ],
          } as NavigationItem,
        ]
      : [];

    if (entity.role === EntityParticipantRole.employee) {
      return [
        chatItem,
        peopleItem,
        callsItem,
        driveItem,
        moreItem,
        ...devSection,
      ];
    }

    return [
      chatItem,
      peopleItem,
      callsItem,
      driveItem,
      {
        type: "link",
        icon: ScheduleIcon,
        title: t({
          id: "home-sidebar-component.schedule",
          defaultMessage: "Schedule",
        }),
        active: activeTab === "schedule",
        requiredRole: [EntityParticipantRole.admin],
        href: `/${entity.id}/schedule`,
        onClick: () => {
          logEvent("navigation_schedule");
        },
      },
      {
        type: "link",
        icon: ClientsIcon,
        title: t({
          id: "home-sidebar-component.Customers",
          defaultMessage: "Customers",
        }),
        active: activeTab === "Customers",
        unreadCount: moduleUnreadIndicators?.customer,
        requiredRole: [EntityParticipantRole.admin],
        href: `/${entity.id}/customers`,
        subscriptionModuleRequired: SubscriptionPlanModuleId.clients,
      },
      {
        type: "link",
        icon: OrderFormsIcon,
        title: t({
          id: "home-sidebar-component.order-forms",
          defaultMessage: "Order Forms",
        }),
        active: activeTab === "orders",
        requiredRole: [EntityParticipantRole.admin],
        href: `/${entity.id}/orders/forms`,
      },
      {
        type: "link",
        icon: WorkspaceIcon,
        title: t({
          id: "home-sidebar-component.workspace",
          defaultMessage: "Workspace",
        }),
        active: activeTab.includes("workspace"),
        requiredRole: [EntityParticipantRole.admin],
        href: `/${entity.id}/workspace/settings`,
      },
      {
        type: "accordion",
        icon: ReportsIcon,
        title: t({
          id: "home-sidebar-component.reports",
          defaultMessage: "Reports",
        }),
        items: [
          {
            type: "link",
            title: t({
              id: "home-sidebar-component.tasks",
              defaultMessage: "Tasks",
            }),
            hidden: true,
            active: activePath === "reports/tasks",
            href: `/${entity.id}/reports/tasks`,
            subscriptionModuleRequired: SubscriptionPlanModuleId.reports,
          },
          {
            type: "link",
            title: t({
              id: "home-sidebar-component.time-clock",
              defaultMessage: "Time Clock",
            }),
            active: activePath === "reports/timeclock",
            href: `/${entity.id}/reports/timeclock`,
            subscriptionModuleRequired: SubscriptionPlanModuleId.reports,
          },
          {
            type: "link",
            title: t({
              id: "home-sidebar-component.scheduled-reports",
              defaultMessage: "Scheduled Reports",
            }),
            active:
              activeTab === TabRoute.scheduledReports ||
              activeTab === TabRoute.scheduledReportsForm,
            requiredRole: [EntityParticipantRole.admin],
            href: `/${entity.id}/scheduled-reports`,
            subscriptionModuleRequired: SubscriptionPlanModuleId.reports,
          },
        ],
      },
      moreItem,
      ...devSection,
    ];
  }, [
    t,
    activeTab,
    moduleUnreadIndicators?.chat,
    moduleUnreadIndicators?.people,
    moduleUnreadIndicators?.drive,
    moduleUnreadIndicators?.call,
    moduleUnreadIndicators?.customer,
    entity.id,
    activePath,
    entity.role,
    logEvent,
    logout,
  ]);
  const tasksNavigationItems = useMemo<TaskNavigationItem[]>(
    () => [
      {
        type: "taskButton",
        title: t({
          id: "home-sidebar-component.my-tasks",
          defaultMessage: "My Tasks",
        }),
        active: activeTab === TabRoute.tasks && activePath === "tasks/board/my",
        icon: <ProjectIcon sign="M" bgColor="#AB59E4" />,
        unreadCount: moduleUnreadIndicators?.task,
        onClick: () => {
          navigateToPage("tasksTasks", { boardId: "my" });
        },
      },
      {
        type: "taskButton",
        title: t({
          id: "home-sidebar-component.team-tasks",
          defaultMessage: "Team Tasks",
        }),
        active:
          activeTab === TabRoute.tasks && activePath === "tasks/board/team",
        icon: <ProjectIcon sign="T" bgColor="#F36021" />,
        unreadCount: moduleUnreadIndicators?.task,
        onClick: () => {
          navigateToPage("tasksTasks", { boardId: "team" });
        },
      },
      ...(boards?.map((board) => ({
        type: "taskButton",
        title: board.name,
        active:
          activeTab === TabRoute.tasks &&
          activePath === `tasks/board/${board.id}`,
        icon: (
          <ProjectIcon sign={board.name.slice(0, 1)} bgColor={board.color} />
        ),
        unreadCount: moduleUnreadIndicators?.task,
        onClick: () => {
          navigateToPage("tasksTasks", { boardId: board.id });
        },
      })) || []),
    ],
    [
      t,
      activeTab,
      activePath,
      moduleUnreadIndicators?.task,
      boards,
      navigateToPage,
    ]
  );

  return {
    navigationItems,
    tasksNavigationItems,
    isSideBarExpanded,
    setIsSideBarExpanded,
    updateIsSideBarExpanded,
  };
};
